import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';

const DetailLabel = ({ label, value, fileType = false }) => {
  return (
    <>
      {!!value && (
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box p={1} />
          <Typography variant="caption">{label}</Typography>
          {!fileType && (
            <Typography variant="subtitle1" align="left">
              {value}
            </Typography>
          )}

          {fileType && (
            <Button
              variant="outlined"
              size="small"
              style={{ display: 'flex' }}
              onClick={() => {
                window.open(value, '_blank');
              }}
            >
              <Icon
                icon="icon-park-outline:preview-open"
                width="25"
                height="25"
              />
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default DetailLabel;
