import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ActionList from 'src/components/ActionList';
import { useProvideUser } from 'src/hooks/useUsers';
import fourSteps from 'src/assets/requests/4steps.png';
import companySvc from 'src/services/company';

const ResgnDirector = () => {
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const { smBelow } = useBreakpoints();
  const { ListUserByCompanyRole, users } = useProvideUser();
  const company = companySvc.getCompany();

  useEffect(() => {
    ListUserByCompanyRole(company.companyId, 5);
  }, []);

  return (
    <>
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change of Officers',
            route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
          },
          {
            path: 'Resignation of Director',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Resignation of Director
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Resignation of Active Director of your business
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                If the resigning director is a local director, it is important
                to note that there MUST be at least ONE local director remaining
                on the board.
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                The Director resignation date and reason for resigning.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Submit a copy of the Director’s resignation letter to Meyzer360.
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={fourSteps} height={50} />

          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Basic Plan: SGD50
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Unlimited Plan: Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 2 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 3-5 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />
          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <ActionList
          title="Select Director for Resignation"
          itemList={users}
          buttonLabel="Resign"
          onClick={(directorId) => {
            history.push(ROUTES.CORPSEC_REQ_RESGN_DIRECTOR_INFO(directorId));
          }}
          onClickBackButton={() => {
            history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
          }}
        />
      )}
      <Box py={5} />
    </>
  );
};

export default ResgnDirector;
