import { Icon } from '@iconify/react';
import {
  AppBar,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  Badge,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import clsx from 'clsx';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import mlogo from 'src/assets/m360.png';
import ROUTES from 'src/constants/routes';
import useBreakpoints from '../../hooks/useBreakpoints';
import useStyles from './AuthLayout.styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import authSvc from 'src/services/authentication';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const AuthLayout = ({ children, contentStyle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { smBelow } = useBreakpoints();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = useState(true);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openList, setOpenList] = useState({
    ['LEGAL']: location.pathname === ROUTES.CORPSEC,
  });
  const { cartList } = useContext(ConfigContext);

  const [user, setUser] = useState('');

  useEffect(() => {
    const data = authSvc.getAuthData();
    setUser(data.user);
  }, []);

  const handleClose = () => {
    setOpenDropDown(false);
    setOpenSetting(false);
    setAnchorEl(null);
  };

  const handleSignout = () => {
    companySvc.removeSelectedCompany();
    authSvc.signOut();
    setUser({});
    history.push(`/`);
    window.location.reload();
    setOpenDropDown(false);
  };

  const changePassword = () => {
    history.push(ROUTES.CHANGE_PASSWORD);
  };

  const handleListClick = (id) => {
    setOpenList((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (smBelow && open === true) {
      setOpen(false);
    } else if (!smBelow && open === false) {
      setOpen(true);
      setOpenSetting(false);
    }
  }, [smBelow]); // detect resizing only

  const renderSettingList = () => {
    return (
      <>
        <IconButton onClick={() => {}}>
          <Icon icon="bx:bxs-bell" className={classes.settingLogo} />
        </IconButton>

        <IconButton
          onClick={() => {
            history.push(ROUTES.PAYMENTCART);
          }}
        >
          <Badge color="error" badgeContent={cartList.length}>
            <Icon
              icon="clarity:shopping-cart-solid"
              className={classes.settingLogo}
            />
          </Badge>
        </IconButton>

        <IconButton
          onClick={() => {
            history.push(ROUTES.ORDERHISTORY);
          }}
        >
          <Icon
            icon="fa-solid:box"
            color="white"
            width={smBelow ? '25' : '15'}
            height={smBelow ? '25' : '15'}
          />
        </IconButton>

        {!smBelow && (
          <Box pl={2} display="flex" flexDirection="column">
            <Typography
              variant="body2"
              align="right"
              style={{ fontWeight: 600 }}
            >
              {`${user.first_name} ${user.last_name}`}
            </Typography>
            <Typography
              variant="caption"
              align="right"
              style={{ opacity: '50%' }}
            >
              {user.email}
            </Typography>
          </Box>
        )}
        <IconButton>
          <Icon
            icon="carbon:user-avatar-filled"
            color="white"
            width={smBelow ? '40' : '30'}
            height={smBelow ? '40' : '30'}
          />
        </IconButton>

        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpenDropDown(true);
          }}
        >
          <Icon icon="ci:settings-filled" className={classes.settingLogo} />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem onClick={handleSignout}>Logout</MenuItem>
          <MenuItem onClick={changePassword}>Change Password</MenuItem>
        </StyledMenu>
      </>
    );
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              {!open && (
                <img src={mlogo} height={25} style={{ marginRight: 15 }} />
              )}
              <Typography variant="h6" noWrap>
                SERVICES OVERVIEW
              </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!smBelow && renderSettingList()}
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <img src={mlogo} height={25} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon className={classes.textlabel} />
              )}
            </IconButton>
          </div>
          <Divider />
          <List
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
            }}
          >
            <ListItem
              selected={location.pathname === ROUTES.DASHBOARD}
              classes={{
                selected: classes.selectedlabel,
              }}
              button
              key={'OVERVIEW'}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon
                  icon="bx:bxs-dashboard"
                  className={classes.textlabel}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.textlabel]: open,
                  [classes.textlabelclose]: !open,
                })}
                primary={'OVERVIEW'}
                onClick={() => {
                  history.push(ROUTES.DASHBOARD);
                }}
              />
            </ListItem>
            <ListItem
              button
              key={'LEGAL'}
              onClick={() => {
                handleListClick('LEGAL');
              }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon
                  icon="octicon:law-16"
                  className={classes.textlabel}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.textlabel]: open,
                  [classes.textlabelclose]: !open,
                })}
                primary={'LEGAL'}
              />
              {openList['LEGAL'] ? (
                <ExpandLess style={{ color: 'white' }} />
              ) : (
                <ExpandMore style={{ color: 'white' }} />
              )}
            </ListItem>
            <Collapse in={openList['LEGAL']}>
              <List component="div" disablePadding>
                <Tooltip title="CORPORATE SECRETARY" arrow placement="right">
                  <ListItem
                    selected={location.pathname === ROUTES.CORPSEC}
                    className={classes.nested}
                    classes={{
                      selected: classes.selectedlabel,
                    }}
                    button
                    key={'CORPORATE SECRETARY'}
                    onClick={() => {
                      history.push(ROUTES.CORPSEC);
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.icon }}>
                      <InboxIcon className={classes.textlabel} />
                    </ListItemIcon>
                    <ListItemText
                      primary={'Corporate Secretariat'}
                      classes={{ primary: classes.subtextlabel }}
                    />
                  </ListItem>
                </Tooltip>
              </List>
            </Collapse>
            <ListItem button key={'FINANCE'}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon
                  icon="ant-design:dollar-circle-filled"
                  className={classes.textlabel}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.textlabel]: open,
                  [classes.textlabelclose]: !open,
                })}
                primary={'FINANCE'}
              />
            </ListItem>
            <ListItem button key={'HR/WORKPLACE'}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon
                  icon="foundation:torso-business"
                  className={classes.textlabel}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.textlabel]: open,
                  [classes.textlabelclose]: !open,
                })}
                primary={'WORKPLACE'}
              />
            </ListItem>
            <ListItem button key={'COMMERCE'}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon
                  icon="clarity:shopping-cart-solid"
                  className={classes.textlabel}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.textlabel]: open,
                  [classes.textlabelclose]: !open,
                })}
                primary={'COMMERCE'}
              />
            </ListItem>
            <ListItem
              button
              key={'COMMERCE'}
              onClick={() => {
                history.push(ROUTES.WEBDEV);
              }}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon
                  icon="ic:round-web"
                  className={classes.textlabel}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.textlabel]: open,
                  [classes.textlabelclose]: !open,
                })}
                primary={'WEBDEV'}
              />
            </ListItem>
            {smBelow && (
              <>
                <div style={{ flexGrow: 1 }} />
                <ListItem
                  button
                  key={'SETTINGS'}
                  onClick={() => {
                    setOpenSetting(true);
                  }}
                >
                  <ListItemIcon classes={{ root: classes.icon }}>
                    <Icon
                      icon="ci:settings-filled"
                      className={classes.textlabel}
                      width="30"
                      height="30"
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={clsx({
                      [classes.textlabel]: open,
                      [classes.textlabelclose]: !open,
                    })}
                    primary={'SETTINGS'}
                  />
                </ListItem>
              </>
            )}
          </List>
        </Drawer>
        <Drawer
          anchor="right"
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          classes={{ paper: classes.settingDrawer }}
        >
          {renderSettingList()}
        </Drawer>
        <main className={classes.content} style={contentStyle}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </>
  );
};

export default AuthLayout;
