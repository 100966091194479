import React from 'react';
import { Field } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { TextField, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { useFormikContext } from 'formik';

const FormDatePicker = ({ name, containerWidth = '100%', ...props }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <>
          <Box style={{ display: 'flex' }} flexDirection="column">
            <Icon icon={props.icon} width="20" height="20" />
            <Typography variant="body2" align="left" style={{ paddingLeft: 5 }}>
              {props.labelText}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              style={{ paddingLeft: 5, fontStyle: 'italic', color: '#808080' }}
            >
              {props.optional && '(optional)'}
            </Typography>

            <DatePicker
              style={{ display: 'flex', width: containerWidth }}
              {...field}
              {...props}
              selected={(field.value && new Date(field.value)) || null}
              onChange={(val) => {
                setFieldValue(field.name, val);
              }}
              error={meta.touched && !!meta.error}
              helperText={
                meta.touched && meta.error ? meta.error : props.helperText || ''
              }
            />
          </Box>
          <Box pb={3} />
        </>
      )}
    </Field>
  );
};

export default FormDatePicker;
