import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';

const requestType = [
  { label: 'Capital & Shares Related' },
  { label: 'General Enquires' },
  { label: 'Officers Related' },
  { label: 'Product & Subscription Related' },
  { label: 'Shareholders Related' },
];

const OtherRequest = () => {
  const company = companySvc.getCompany();

  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(0);
  const { smBelow } = useBreakpoints();
  const {
    CreateOtherRequestApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  // const {
  //   GetFinancialYearEnd,
  //   GetAGM,
  //   agm,
  //   financialYearEnd,
  //   companyError,
  // } = useProvideCompany();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, cartSubmitting } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  const validationSchema = Yup.object({
    type: Yup.string().required('Type is required'),
    description: Yup.string().required('Description is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(19);
  }, []);

  // useEffect(() => {
  //   if (companyError) {
  //     showErrorSnackbar(
  //       `Error loading Company's other request. ${companyError}`,
  //     );
  //   }
  // }, [companyError]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;

    let res = await CreateOtherRequestApplication(values);
    if (res.status === 'success') {
      console.log('res', res);
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.other_request_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const stepOne = () => {
    return (
      <>
        <Typography variant="h6" color="secondary">
          Other Request
        </Typography>
        <Box p={1} />
        <Typography variant="h6" color="secondary">
          What can I do in this request?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              <b>Anything!</b> Any Secretarial Service Request! If you need
              Secretarial Service outside of those that we have listed in this
              APP, please use this Other Request form to submit your request to
              us. <br />
              We will review your request at the earliest time and contact you
              should there be any clarifications.
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              We hope to be of service to you!
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What information / document(s) do I need?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Any information and/ or document related to your request that will
              help us to process your request faster.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What are the steps?
        </Typography>
        <img src={fourSteps} height={50} />
        <Typography variant="h6" color="secondary">
          How much does it cost?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Varies. Depending on the complexity of the service required.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          How long does it take?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Time to fill form: {'<'} 5 min.
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Average Processing Time: Varies
            </Typography>
          </li>
        </ul>
        <Box p={1} />

        <MzButton
          title={'Lets get started'}
          onClick={() => {
            setStep(1);
          }}
        />
      </>
    );
  };

  const stepTwo = (props) => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
      >
        <Box p={3}>
          <FormDropdown
            autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
            name="type"
            onChange={(event, newValue) => {
              props.setFieldValue('type', newValue?.label);
            }}
            optionList={requestType}
            labelText="Type *"
            placeholder="Select Type"
            fullWidth
            containerWidth="50%"
            shrink
            variant="outlined"
          />
          <FormTextField
            name="description"
            labelText="Description *"
            placeholder="Enter Description"
            shrink
            variant="outlined"
            color="secondary"
            multiline
            rows={10}
            fullWidth={smBelow ? true : false}
          />
        </Box>
      </Paper>
    );
  };

  return (
    <>
      <AlertBox
        title="Successfully submitted!"
        isVisible={showAlert}
        topButtonLabel="Add More"
        topButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS);
        }}
        topButtonStyles={{
          width: '250px',
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #000000',
        }}
        botButtonLabel="Go to Transaction History"
        botButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonStyles={{
          width: '250px',
          color: '#FFFFFF',
          backgroundColor: '#FFB800',
        }}
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Filling of Other Resolutions',
            route: ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS,
          },
          {
            path: 'Other Request',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Formik
        initialValues={{
          type: '',
          description: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {step === 0 && stepOne()}
              {step === 1 && stepTwo(props)}
              <Box p={2} />
              {step === 1 && (
                <MzButton
                  disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  onClick={() => {
                    console.log('other request');
                  }}
                  title={'Submit'}
                  loading={cartSubmitting || requestSubmitting}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <Box p={4} />
    </>
  );
};

export default OtherRequest;
