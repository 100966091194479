import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideRequest } from 'src/hooks/useRequests';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import companySvc from 'src/services/company';

const OpenBankAccount = () => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [bankName, setBankName] = React.useState();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(0);
  const { smBelow } = useBreakpoints();
  const {
    CreateOpenBankAccApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { ReadProduct, product, productError } = useProvideProduct();
  const { addCart, user, cartSubmitting } = useContext(ConfigContext);
  const company = companySvc.getCompany();
  const { showErrorSnackbar } = useSnackbar();
  const [currency, setCurrency] = React.useState({
    sgd: false,
    usd: false,
  });

  useEffect(() => {
    // get latest product
    ReadProduct(17);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const validationSchema = Yup.object({
    otherbankname: Yup.string().when('bankName', {
      is: () => bankName === 'others',
      then: Yup.string().required('Bank Name is required'),
    }),
  });

  const handleCurrencyChange = (event) => {
    setCurrency({ ...currency, [event.target.name]: event.target.checked });
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 7;
    values.created_by = user.user_id;
    values.bank_name = bankName === 'others' ? values.otherbankname : bankName;
    values.is_sgq_account = !currency.sgd ? 0 : 1;
    values.is_usd_account = !currency.usd ? 0 : 1;
    let res = await CreateOpenBankAccApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications.open_bank_account_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const stepOne = () => {
    return (
      <>
        <Typography variant="h6" color="secondary">
          Open Bank Account
        </Typography>
        <Box p={1} />
        <Typography variant="h6" color="secondary">
          What can I do in this request?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Get company approval/ authorization document for The Opening of
              SGD and/ or USD Bank Account.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What information / document(s) do I need?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Name of Bank to open company bank account
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Options to open SGD and/ or USD current account
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What are the steps?
        </Typography>
        <img src={fourSteps} height={50} />
        <Typography variant="h6" color="secondary">
          How much does it cost?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Basic Plan: SGD50
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Unlimited Plan: Free
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          How long does it take?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Time to fill form: {'<'} 2 min.
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Average Processing Time: 3-5 days
            </Typography>
          </li>
        </ul>
        <Box p={1} />

        <MzButton
          title={'Lets get started'}
          onClick={() => {
            setStep(1);
          }}
        />
      </>
    );
  };

  const stepTwo = () => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
      >
        <Box p={3}>
          <Typography variant="body1" align="left">
            Select Bank (Must tick ONE box)
          </Typography>
          <RadioGroup
            name="bankName"
            value={bankName}
            onChange={() => setBankName(event.target.value)}
          >
            <FormControlLabel
              value="dbs"
              control={<Radio />}
              label="DBS Bank"
            />
            <FormControlLabel
              value="ocbc"
              control={<Radio />}
              label="OCBC Bank"
            />
            <FormControlLabel
              value="uob"
              control={<Radio />}
              label="UOB Bank"
            />
            <FormControlLabel
              value="others"
              control={<Radio />}
              label="Others"
            />
          </RadioGroup>
          {bankName === 'others' && (
            <FormTextField
              name="otherbankname"
              labelText="Enter Bank Name"
              fullWidth={smBelow ? true : false}
              shrink
              variant="outlined"
            />
          )}
          <Box p={1} />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={currency.sgd}
                  onChange={handleCurrencyChange}
                  name="sgd"
                />
              }
              label="SGD Current Account"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={currency.usd}
                  onChange={handleCurrencyChange}
                  name="usd"
                />
              }
              label="USD Current Account"
            />
          </FormGroup>
        </Box>
      </Paper>
    );
  };

  return (
    <>
      <AlertBox
        title="Request successfully added to cart!"
        isVisible={showAlert}
        topButtonLabel="Add More"
        topButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS);
        }}
        topButtonStyles={{
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #000000',
        }}
        botButtonLabel="Go to cart"
        botButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonStyles={{
          color: '#FFFFFF',
          backgroundColor: '#FFB800',
        }}
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Filling of Other Resolutions',
            route: ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS,
          },
          {
            path: 'Open Bank Account ',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Formik
        initialValues={{
          bankName: '',
          otherbankname: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {step === 0 && stepOne()}
              {step === 1 && stepTwo()}
              <Box p={2} />
              {step === 1 && (
                <MzButton
                  disabled={
                    bankName === 'others'
                      ? !(props.isValid && props.dirty)
                      : !bankName
                  }
                  type="submit"
                  onClick={() => {}}
                  title={'Submit'}
                  loading={cartSubmitting || requestSubmitting}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <Box p={4} />
    </>
  );
};

export default OpenBankAccount;
