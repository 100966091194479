import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideProduct } from 'src/hooks/useProduct';
import AlertBox from 'src/components/AlertBox';
import companySvc from 'src/services/company';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { useProvideData } from 'src/hooks/useData';
import { useProvideCompany } from 'src/hooks/useCompany';

const ChangeCompanyAddress = () => {
  // const classes = useStyles();
  const company = companySvc.getCompany();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [showAlert, setShowAlert] = useState(false);

  const { countries, ListCountries } = useProvideData();
  const {
    CreateCompanyAddressApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user, cartSubmitting } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    ReadCompanyAddresses,
    companyAddressess,
    companyError,
  } = useProvideCompany();

  const validationSchema = Yup.object({
    address_line_1: Yup.string().required('Address Line 1 is required'),
    postal_code: Yup.string().required('Postal Code is required'),
    country_id: Yup.string().required('Country is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(10);
    ReadCompanyAddresses(company.companyId);
    ListCountries();
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (companyError) {
      showErrorSnackbar(`Error loading address. ${companyError}`);
    }
  }, [companyError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getCompanyBusinessAddr = () => {
    const addr = companyAddressess.find(
      (addr) =>
        addr.address_type_id === 2 || addr.address_type_name === 'Business',
    );

    return [
      addr.floor_unit_no,
      addr.address_line_1,
      addr.address_line_2,
      addr.address_line_3,
      addr.postal_code,
      addr.country_name,
    ]
      .filter((addr) => addr)
      .join(', ');
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11; // in cart status
    let res = await CreateCompanyAddressApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.company_address_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const renderForm = () => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
        elevation={4}
      >
        <Formik
          initialValues={{
            postal_code: '',
            floor_unit_no: '',
            address_line_1: '',
            address_line_2: '',
            address_line_3: '',
            country_id: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Box
                p={3}
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Typography variant="caption">
                  Current Registered Address
                </Typography>
                <Typography variant="h6">{`${getCompanyBusinessAddr()}`}</Typography>
                <Box py={1} />
                <FormTextField
                  id="postal_code"
                  name="postal_code"
                  labelText="Postal Code *"
                  placeholder="Postal Code"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
                <FormTextField
                  name="floor_unit_no"
                  labelText="Floor/ Unit No"
                  placeholder="Unit No"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
                <FormTextField
                  name="address_line_1"
                  labelText="Address Line 1 *"
                  placeholder="Address Line 1"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
                <FormTextField
                  name="address_line_2"
                  labelText="Address Line 2"
                  placeholder="Address Line 2"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
                <FormTextField
                  name="address_line_3"
                  labelText="Address Line 3"
                  placeholder="Address Line 3"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
                <FormDropdown
                  autoCompleteStyle={{ width: '50%' }}
                  name="country_id"
                  onChange={(event, newValue) => {
                    props.setFieldValue('country_id', newValue?.value);
                  }}
                  optionList={countries}
                  labelText="Country *"
                  placeholder="Select One"
                  fullWidth
                  shrink
                  variant="outlined"
                />
                <MzButton
                  disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  title={'SUBMIT'}
                  onClick={() => {}}
                  loading={requestSubmitting || cartSubmitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  };

  return (
    <>
      <AlertBox
        title="Item added to cart"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
        topButtonLabel="View your cart"
        botButtonLabel="Back to requests"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Company Particulars',
            route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
          },
          {
            path: 'Change Company Address',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Change Company Address
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Change the Registered Address for our business
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                New Registered Address for the business
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={fourSteps} height={50} />
          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Basic Plan: SGD50
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Unlimited Plan: Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 2 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 3 - 5 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />
          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <>
          <Typography variant="h6" color="secondary">
            Change Company Address
          </Typography>
          <Box py={2} />
          {renderForm()}
        </>
      )}
      <Box py={5} />
    </>
  );
};

export default ChangeCompanyAddress;
