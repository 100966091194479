import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { Icon } from '@iconify/react';
import useSnackbar from '../hooks/useSnackbar';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';
import IntroBar from '../components/IntroBar/IntroBar.js';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  containerRoot: {
    alignItems: 'column',
  },
  // necessary for content to be below app bar
  toolbar: {
    toolbar: theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 130,
    },
  },
}));

function Otppage() {
  const classes = useStyles();
  const history = useHistory();
  const [otp, setOtp] = useState('');

  const {
    showWarningSnackbar,
    showSuccessSnackbar,
    showErrorSnackbar,
  } = useSnackbar();

  return (
    <Box className={classes.root}>
      <IntroBar
        title="Enter phone OTP"
        subTitle="We have sent a one-time password (OTP) via SMS to your phone number."
      />
      <Container className={classes.containerRoot}>
        <Toolbar className={classes.toolbar} />
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(222, 173, 0, 1)',
          }}
          onClick={() => {
            history.push(ROUTES.SIGNINPAGE);
          }}
        >
          <Icon icon="eva:arrow-ios-back-fill" width="20" height="20" />
          <Typography
            variant="body1"
            align="left"
            style={{
              fontWeight: 600,
            }}
          >
            Back
          </Typography>
        </Box>
        <Container
          style={{
            paddingTop: 100,
          }}
        >
          <Box
            style={{
              padding: 50,
              borderRadius: 25,
              boxShadow: '0 0 10px #ccc',
            }}
          >
            <Typography variant="h6" align="center">
              Please enter the 4-digit OTP sent to
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{
                color: 'rgba(222, 173, 0, 1)',
              }}
            >
              +65 3213 2132
            </Typography>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 50,
                paddingBottom: 50,
              }}
            >
              <OtpInput
                value={otp}
                onChange={(newValue) => {
                  setOtp(newValue);
                  if (newValue.length == 4) {
                    history.push(ROUTES.DASHBOARD);
                  }
                }}
                numInputs={4}
                isInputNum
                shouldAutoFocus={true}
                inputStyle={{
                  width: '3rem',
                  height: '3rem',
                  margin: '0 0.5rem',
                  borderRadius: 4,
                  border: '1px solid rgba(222, 173, 0, 0.3)',
                  backgroundColor: 'rgba(222, 173, 0, 0.3)',
                  color: '#FFFFFF',
                  fontWeight: 600,
                  fontSize: 22,
                }}
                focusStyle={{
                  backgroundColor: 'rgba(222, 173, 0, 0.74)',
                  outline: 'none',
                }}
              />
            </Box>
            <Typography
              variant="body1"
              align="center"
              style={{
                color: 'rgba(222, 173, 0, 1)',
                fontWeight: 600,
              }}
              onClick={() => {
                showSuccessSnackbar(
                  'A new OTP has been sent to +65 3213 2132.',
                );
              }}
            >
              Resend OTP
            </Typography>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}

export default Otppage;
