import React from 'react';
import { Link, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useStyles from './BreadcrumbsNav.styles';
import { useHistory } from 'react-router-dom';

const BreadcrumbsNav = ({ pathList, styles }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" color="secondary" />}
      style={styles}
    >
      {pathList.map((list, index) => {
        const currentPath = pathList.length - 1 === index;
        return (
          <Link
            key={index}
            color="secondary"
            style={{
              cursor: currentPath ? 'auto' : 'pointer',
              fontWeight: 600,
              textDecoration: currentPath ? 'none' : 'underline',
            }}
            onClick={() => (currentPath ? '' : history.push(list.route))}
            variant="subtitle2"
          >
            {list.path}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
