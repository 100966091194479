import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Avatar,
  Paper,
} from '@material-ui/core';
import { StringMasking } from 'src/utils/string';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import Stepper from 'src/components/Stepper';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideUser } from 'src/hooks/useUsers';
import { useProvideUserAddresses } from 'src/hooks/useUserAddresses';
import { useProvideData } from 'src/hooks/useData';
import { useProvideRequest } from 'src/hooks/useRequests';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';
import { ConfigContext } from 'src/contexts';
import companySvc from 'src/services/company';
import AlertBox from 'src/components/AlertBox';

const tempSteps = [
  {
    id: 1,
    label: 'Change Address',
  },
  {
    id: 2,
    label: 'Change Contact Info',
  },
  {
    id: 3,
    label: 'Change Residency Status',
  },
  {
    id: 4,
    label: 'Change Name',
  },
];

const ChangeDirectorParticulars = () => {
  const history = useHistory();
  const params = useParams();
  const { countries, idTypes, ListIDType, ListCountries } = useProvideData();
  const { user, userContacts, GetUserContact, GetUserByID } = useProvideUser();
  const {
    CreateUpdateParticularsApplication,
    requestError,
  } = useProvideRequest();
  const { userAddressess, ListUserAddressess } = useProvideUserAddresses();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user: loggedInUser } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();
  const [showAlert, setShowAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const company = companySvc.getCompany();

  useEffect(() => {
    GetUserByID(params.id);
    GetUserContact(params.id);
    ListUserAddressess(params.id);
    ListIDType();
    ListCountries();
    ReadProduct(9);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const buttonClasses = useButtonStyle();
  const [tabValue, setTabValue] = useState(0);
  const [step, setStep] = useState(0);
  const { smBelow } = useBreakpoints();
  const [countryCode, setCountryCode] = useState('SG');

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    postal_code: Yup.string().required('Postal Code is required'),
    country_id: Yup.string().required('Country is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
  });

  const getBusinessAddress = () => {
    return userAddressess.find(
      (address) => address.address_type_name === 'Business',
    );
  };

  const getContactEmail = () => {
    return userContacts.find(
      (contact) => contact.contact_type_name === 'email',
    );
  };

  const getContactMobile1 = () => {
    return userContacts.find(
      (contact) => contact.contact_type_name === 'mobile1',
    );
  };

  const getDefaultCountry = (countryID) => {
    return countries.find((country) => country.country_id === countryID);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const stepOne = (props) => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
      >
        <Box p={3}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Local" />
            <Tab label="Foreigner" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Box pt={3}>
              <FormTextField
                id="postal_code"
                name="postal_code"
                labelText="Postal Code *"
                placeholder="Postal Code"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="floor_unit_no"
                labelText="Floor/ Unit No *"
                placeholder="Unit No"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_1"
                labelText="Address Line 1"
                placeholder="Address Line 1"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_2"
                labelText="Address Line 2"
                placeholder="Address Line 2"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_3"
                labelText="Address Line 3"
                placeholder="Address Line 3"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormDropdown
                autoCompleteStyle={{ width: '50%' }}
                name="country_id"
                onChange={(event, newValue) => {
                  props.setFieldValue('country_id', newValue?.value);
                }}
                optionList={countries}
                labelText="Country *"
                placeholder="Select One"
                fullWidth
                shrink
                variant="outlined"
                defaultValue={getDefaultCountry(props.values.country_id)}
              />
              <FormFileDropzone
                containerWidth="50%"
                name="proofOfIdentity"
                labelText="Proof of Identity*"
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box pt={3}>
              <FormTextField
                name="postal_code"
                labelText="Postal Code *"
                placeholder="Postal Code"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="floor_unit_no"
                labelText="Floor/ Unit No *"
                placeholder="Unit No"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_1"
                labelText="Address Line 1"
                placeholder="Address Line 1"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_2"
                labelText="Address Line 2"
                placeholder="Address Line 2"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_3"
                labelText="Address Line 3"
                placeholder="Address Line 3"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormDropdown
                autoCompleteStyle={{ width: '50%' }}
                name="country_id"
                onChange={(event, newValue) => {
                  props.setFieldValue('country_id', newValue?.value);
                }}
                optionList={countries}
                labelText="Country *"
                placeholder="Singapore"
                fullWidth
                containerWidth="50%"
                shrink
                variant="outlined"
                defaultValue={getDefaultCountry(props.values.country_id)}
              />
              <FormFileDropzone
                containerWidth="50%"
                name="proofOfIdentity"
                labelText="Proof of Identity*"
              />
            </Box>
          </TabPanel>
        </Box>
      </Paper>
    );
  };

  const stepTwo = () => {
    return (
      <>
        <Typography color="secondary" variant="h5">
          Change Mobile Number
        </Typography>
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box
            p={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Typography variant="caption">Current Local Mobile No</Typography>
            <Typography variant="subtitle1">
              {getContactMobile1()?.country_phone_code_id +
                getContactMobile1()?.contact_value}
            </Typography>
            <Box p={1} />
            <FormDropdownTextField
              containerWidth="50%"
              name="mobile_number"
              labelText="Change to*"
              placeholder="91234567"
              fullWidth
              shrink
              variant="outlined"
              showFlag={false}
              onValueChange={(event, newValue) => {
                setCountryCode(newValue.code);
              }}
              countryCode={countryCode}
            />
          </Box>
        </Paper>
        <Box p={2} />
        <Typography color="secondary" variant="h5">
          Change Email Address
        </Typography>
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box
            p={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Typography variant="caption">Change Email Address</Typography>
            <Typography variant="subtitle1">
              {getContactEmail()?.contact_value}
            </Typography>
            <Box p={1} />
            <FormTextField
              name="email"
              labelText="Change To *"
              placeholder="Email"
              containerWidth="50%"
              shrink
              variant="outlined"
            />
          </Box>
        </Paper>
      </>
    );
  };

  const stepThree = (props) => {
    return (
      <>
        <Typography color="secondary" variant="h5">
          Change Nationality
        </Typography>
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box
            p={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Typography variant="caption">Current Nationality</Typography>
            <Typography variant="subtitle1">{user.nationality}</Typography>
            <Box p={1} />
            <FormDropdown
              containerWidth="50%"
              autoCompleteStyle={{ width: '50%' }}
              name="nationality_country_id"
              onChange={(event, newValue) => {
                props.setFieldValue('nationality_country_id', newValue?.value);
              }}
              optionList={countries}
              labelText="Change To *"
              placeholder="Singapore"
              fullWidth
              shrink
              variant="outlined"
              defaultValue={getDefaultCountry(user.nationality_id)}
            />
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              style={{
                gap: '20px',
                width: '100%',
              }}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                style={{
                  flexBasis: '50%',
                  flexGrow: '0',
                }}
              >
                <Typography variant="caption">Current ID Type</Typography>
                <Typography variant="subtitle1">NRIC (CITIZEN)</Typography>
                <Box p={1} />
                <FormDropdown
                  autoCompleteStyle={{ width: '50%' }}
                  name="identification_type_id"
                  onChange={(event, newValue) => {
                    props.setFieldValue(
                      'identification_type_id',
                      newValue?.value,
                    );
                  }}
                  optionList={idTypes}
                  labelText="ID Type"
                  placeholder="NRIC"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                style={{
                  flexBasis: '50%',
                  flexGrow: '0',
                }}
              >
                <Typography variant="caption">Current ID</Typography>
                <Typography variant="subtitle1">
                  {StringMasking(`${user?.nric || user?.passport || ''}`)}
                </Typography>
                <Box p={1} />
                <FormTextField
                  name="identification_value"
                  labelText="Current ID *"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </>
    );
  };

  const stepFour = () => {
    return (
      <>
        <Typography color="secondary" variant="h5">
          Change Name
        </Typography>
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box
            p={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Typography variant="caption">Current Name</Typography>
            <Typography variant="subtitle1">{`${user?.first_name} ${user?.last_name}`}</Typography>

            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              style={{
                gap: '20px',
              }}
            >
              <FormTextField
                name="first_name"
                labelText="First Name *"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                labelText="Last Name *"
                name="last_name"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
            </Box>

            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              style={{
                gap: '20px',
              }}
            >
              <FormDatePicker
                color="secondary"
                name="name_change_date"
                labelText="Date of Name Change *"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
              />
              <FormTextField
                name="deed_poll_date"
                labelText="Date of Deed Poll *"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
            </Box>
            <FormFileDropzone
              containerWidth="50%"
              name="deedPollFiles"
              labelText="Deed Poll or Evidential Document *"
            />
          </Box>
        </Paper>
      </>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    if (step === 3) {
      setSubmitting(true);
      values.state_id = 1;
      values.company_application_status_id = 11; // in cart status
      values.company_id = company.companyId;
      values.user_id = params.id;
      values.created_by = loggedInUser.user_id;
      values.individual_type_id = 0;
      try {
        if (values.proofOfIdentity.length > 0) {
          const proofOfIdentityID = await S3UploadAndInsertDocuments(
            values.proofOfIdentity[0],
            76,
            6,
            'officer_application_id',
          );
          values.identity_attachment_id = proofOfIdentityID.document_id;
        }

        if (values.deedPollFiles.length > 0) {
          const deedPollFilesID = await S3UploadAndInsertDocuments(
            values.deedPollFiles[0],
            76,
            6,
            'officer_application_id',
          );
          values.deed_poll_attachment_id = deedPollFilesID.document_id;
        }
      } catch (err) {
        setSubmitting(false);
        showErrorSnackbar('Error uploading documents');
        return;
      }

      let res = await CreateUpdateParticularsApplication(values);
      if (res.status === 'success') {
        res = await addCart({
          price: product.price,
          product_id: product.id,
          quantity: 1,
          user_id: loggedInUser.user_id,
          product_detail_id:
            res.body.applications.individual_particular_application_id,
        });
        if (res.status === 'success') {
          actions.resetForm();
          setShowAlert(true);
        }
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <AlertBox
        title="Item added to cart"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
        topButtonLabel="View your cart"
        botButtonLabel="Back to requests"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_DIRECTOR_LIST);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Personal Particulars',
            route: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
          },
          {
            path: 'Select Director to Change Particulars',
            route: ROUTES.CORPSEC_REQ_DIRECTOR_LIST,
          },
          {
            path: 'Update',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Stepper
        steps={tempSteps}
        providedStep={step}
        setProvidedStep={(step) => {
          setStep(step);
        }}
      />

      <Formik
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
          identification_value: user?.nric || user?.passport,
          email: getContactEmail()?.contact_value,
          mobile_number: getContactMobile1()?.contact_value,
          identification_type_id: '',
          proofOfIdentity: [],
          deedPollFiles: [],
          postal_code: getBusinessAddress()?.postal_code,
          floor_unit_no: getBusinessAddress()?.postal_code,
          address_line_1: getBusinessAddress()?.address_line_1,
          address_line_2: getBusinessAddress()?.address_line_2,
          address_line_3: getBusinessAddress()?.address_line_3,
          country_id: getBusinessAddress()?.country_id,
          nationality_country_id: user?.nationality_id,
        }}
        initialTouched={{
          mobile_number: true,
          first_name: true,
          last_name: true,
          identification_value: true,
          email: true,
          mobile_number: true,
          identification_type_id: true,
          proofOfIdentity: true,
          deedPollFiles: true,
          postal_code: true,
          floor_unit_no: true,
          address_line_1: true,
          address_line_2: true,
          address_line_3: true,
          country_id: true,
          nationality_country_id: true,
        }}
        enableReinitialize
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {step === 0 && stepOne(props)}
              {step === 1 && stepTwo()}
              {step === 2 && stepThree(props)}
              {step === 3 && stepFour()}
              <Box p={2} />
              {step === 3 && (
                <MzButton
                  loading={submitting}
                  type="submit"
                  onClick={() => {}}
                  title={'Submit'}
                />
              )}
              {step !== 3 && (
                <MzButton
                  onClick={() => {
                    if (step < 3) {
                      setStep((prevState) => {
                        return prevState + 1;
                      });
                    }
                  }}
                  title={'Continue'}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>

      <Box p={4} />
    </>
  );
};

export default ChangeDirectorParticulars;
