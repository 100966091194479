import React, { useEffect, forwardRef } from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from './BoardRoom.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import { useProvideBoardroom } from 'src/hooks/useBoardroom';

const BoardRoom = () => {
  const classes = useStyles();
  const { xsBelow, mdBelow } = useBreakpoints();
  const { ListBoardrooms, boardrooms } = useProvideBoardroom();
  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  };

  useEffect(() => {
    ListBoardrooms();
  }, []);

  const mapBoardrooms = () => {
    return boardrooms.map((board, index) => ({
      name: board.first_name + ' ' + board.last_name,
      nationality: board.passport ? board.passport : board.nric,
      status: 1,
      appointmentDate: board.date_of_appointment.substring(0, 10),
      resignationDate: board.date_of_appointment.substring(0, 10),
    }));
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={xsBelow ? 'column' : 'row'}
        justifyContent="space-around"
        width="100%"
      >
        <Box
          width={mdBelow ? (xsBelow ? '100%' : '33%') : '25%'}
          style={{
            padding: 30,
            borderRadius: 5,
            boxShadow: '0 0 10px #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" className={classes.fontWeight}>
            {boardrooms.length}
          </Typography>
          <Box p={1} />
          <Typography variant="body1">Serving Board Members</Typography>
        </Box>
        <Box p={1} />
        <Box
          width={mdBelow ? (xsBelow ? '100%' : '33%') : '25%'}
          style={{
            padding: 30,
            borderRadius: 5,
            boxShadow: '0 0 10px #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" className={classes.fontWeight}>
            0
          </Typography>
          <Box p={1} />
          <Typography variant="body1">Pending Board Members</Typography>
        </Box>
        <Box p={1} />
        <Box
          width={mdBelow ? (xsBelow ? '100%' : '33%') : '25%'}
          style={{
            padding: 30,
            borderRadius: 5,
            boxShadow: '0 0 10px #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" className={classes.fontWeight}>
            0
          </Typography>
          <Box p={1} />
          <Typography variant="body1">Past Board Members</Typography>
        </Box>
      </Box>
      <Box p={3} />
      <MaterialTable
        options={{
          filtering: true,
          paging: false,
          toolbar: false,
          draggable: false,
        }}
        style={{ width: '100%' }}
        icons={tableIcons}
        columns={[
          { title: 'Director Name', field: 'name' },
          { title: 'Nationality', field: 'nationality' },
          {
            title: 'Status',
            field: 'status',
            lookup: { 1: 'Pending', 2: 'Passed', 3: 'Failed' },
          },
          {
            title: 'Appointment Date',
            field: 'appointmentDate',
          },
          {
            title: 'Resignation Date',
            field: 'resignationDate',
          },
        ]}
        data={mapBoardrooms()}
      />
      <Box p={5} />
    </>
  );
};

export default BoardRoom;
