import React from 'react';
import { Box } from '@material-ui/core';
import EnhancedTextField from '../EnhancedTextField/EnhancedTextField.js';
import { EnhancedTextFieldStandard } from 'src/components/Enhanced';
import { Field } from 'formik';

const FormTextField = ({ name, variant, ...props }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <>
        <Box
          display="flex"
          flexDirection="column"
          width={props.fullWidth ? '100%' : '50%'}
        >
          <EnhancedTextField
            variant={variant}
            {...field}
            {...props}
            error={meta.touched && !!meta.error}
            helperText={
              meta.touched && meta.error ? meta.error : props.helperText || ''
            }
          />
        </Box>
      </>
    )}
  </Field>
);

export default FormTextField;
