import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    mainTitle: {
      paddingBottom: 25,
      fontWeight: 600,
    },
  }),
);
