import { Box, Button, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthLayout from 'src/components/AuthLayout';
import { ConfigContext } from 'src/contexts';
import * as Yup from 'yup';
import FormTextField from '../components/FormTextField/FormTextField.js';
import ProgressLoader from '../components/Progress';
import ROUTES from '../constants/routes';
import authSvc from '../services/authentication';
import AlertBox from 'src/components/AlertBox';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#161616',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

function ResetPasswordPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [msg, setMsg] = React.useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required('Old password is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Password Mismatch'),
  });

  const formikOnSubmit = async (values, actions) => {
    setLoading(true);
    setMsg('');
    await authSvc.changePassword(values).then(
      (rs) => {
        setLoading(false);
        setShowAlert(true);
      },
      (err) => {
        setMsg(err.message);
        setLoading(false);
      },
    );
  };

  return (
    <>
      <AlertBox
        title="Reset Password Successful"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.DASHBOARD);
        }}
        topButtonLabel="Go To Dashboard"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <AuthLayout
        contentStyle={{
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Container>
              <Formik
                initialValues={{
                  oldPassword: '',
                  password: '',
                  confirmPassword: '',
                }}
                onSubmit={formikOnSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <Form>
                    <Box
                      style={{
                        padding: 30,
                        borderRadius: 25,
                        boxShadow: '0 0 10px #ccc',
                      }}
                    >
                      <FormTextField
                        type="password"
                        name="oldPassword"
                        icon="mdi:lock"
                        labelText="Old Password"
                        placeholder="********"
                        fullWidth
                        shrink
                        variant="standard"
                        error
                      />
                      <FormTextField
                        type="password"
                        name="password"
                        icon="mdi:lock"
                        labelText="New Password"
                        placeholder="********"
                        fullWidth
                        shrink
                        variant="standard"
                        error
                      />
                      <FormTextField
                        type="password"
                        name="confirmPassword"
                        icon="mdi:lock"
                        labelText="Confirm Password"
                        placeholder="********"
                        fullWidth
                        shrink
                        variant="standard"
                        error
                      />
                      <Button
                        color="primary"
                        disabled={!(props.isValid && props.dirty)}
                        type="submit"
                        variant="contained"
                        style={{
                          backgroundColor: !(props.isValid && props.dirty)
                            ? null
                            : '#DEAD00',
                          color: '#FFFFFF',
                          borderRadius: 50,
                          width: 180,
                          textTransform: 'initial',
                          fontWeight: 600,
                        }}
                      >
                        Continue
                      </Button>
                      <p>{msg}</p>
                      <ProgressLoader loading={loading} />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Container>
          </main>
        </div>
      </AuthLayout>
    </>
  );
}

ResetPasswordPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResetPasswordPage;
