import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormDatePicker } from 'src/components/Form';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import { ConfigContext } from 'src/contexts';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import companySvc from 'src/services/company';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideProduct } from 'src/hooks/useProduct';

const DeclareInterimDividend = () => {
  const history = useHistory();
  const {
    CreateDeclareInterimDividend,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { addCart, user, cartSubmitting } = useContext(ConfigContext);
  const [showAlert, setShowAlert] = useState(false);
  const { ReadProduct, product, productError } = useProvideProduct();
  const { showErrorSnackbar } = useSnackbar();

  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(0);
  const { smBelow } = useBreakpoints();
  const company = companySvc.getCompany();

  const validationSchema = Yup.object({
    dividend_amount: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Total Dividend Amount is required'),
    dividend_date: Yup.string().required('Dividend Date is required'),
    payment_date: Yup.string().required('Payment Date is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(16);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const stepOne = () => {
    return (
      <>
        <Typography variant="h6" color="secondary">
          Declare Interim Dividend
        </Typography>
        <Box p={1} />
        <Typography variant="h6" color="secondary">
          What can I do in this request?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Declare Interim Dividend for our business
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What information / document(s) do I need?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Amount of Dividend to be paid
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Date of Dividend
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Date of Payment
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What are the steps?
        </Typography>
        <img src={fourSteps} height={50} />
        <Typography variant="h6" color="secondary">
          How much does it cost?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Basic Plan: SGD50
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Unlimited Plan: Free
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          How long does it take?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Time to fill form: {'<'} 2 min.
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Average Processing Time: 3-5 days
            </Typography>
          </li>
        </ul>
        <Box p={1} />

        <MzButton
          title={'Lets get started'}
          onClick={() => {
            setStep(1);
          }}
        />
      </>
    );
  };

  const stepTwo = () => {
    return (
      <>
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box p={3}>
            <FormTextField
              name="dividend_amount"
              labelText="Total Dividend Amount *"
              fullWidth={smBelow ? true : false}
              shrink
              variant="outlined"
            />
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              style={{
                gap: '20px',
              }}
            >
              <FormDatePicker
                color="secondary"
                name="dividend_date"
                labelText="Dividend Date *"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
              />
              <FormDatePicker
                color="secondary"
                name="payment_date"
                labelText="Payment Date *"
                format="dd/MM/yyyy"
                variant="outlined"
                inputVariant="outlined"
              />
            </Box>
          </Box>
        </Paper>
      </>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 7;
    values.created_by = user.user_id;
    let res = await CreateDeclareInterimDividend(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications.dividend_declaration_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  return (
    <>
      <AlertBox
        title="Request successfully added to cart!"
        isVisible={showAlert}
        topButtonLabel="Add More"
        topButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS);
        }}
        topButtonStyles={{
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #000000',
        }}
        botButtonLabel="Go to cart"
        botButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonStyles={{
          color: '#FFFFFF',
          backgroundColor: '#FFB800',
        }}
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Filling of Other Resolutions',
            route: ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS,
          },
          {
            path: 'Declare Interim Dividend',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Formik
        initialValues={{
          dividend_amount: '',
          dividend_date: new Date(),
          payment_date: new Date(),
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {step === 0 && stepOne()}
              {step === 1 && stepTwo()}
              <Box p={2} />
              {step === 1 && (
                <MzButton
                  disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  onClick={() => {}}
                  title={'Submit'}
                  loading={cartSubmitting || requestSubmitting}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <Box p={4} />
    </>
  );
};

export default DeclareInterimDividend;
