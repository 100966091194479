import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormDatePicker } from 'src/components/Form';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import AlertBox from 'src/components/AlertBox';
import companySvc from 'src/services/company';

const ResgnDirectorInfo = () => {
  const history = useHistory();
  const buttonClasses = useButtonStyle();
  const { smBelow } = useBreakpoints();
  const { addCart, user } = useContext(ConfigContext);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [showAlert, setShowAlert] = useState(false);
  const company = companySvc.getCompany();

  const {
    CreateCessationApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();
  const { ReadProduct, product, productError } = useProvideProduct();

  useEffect(() => {
    // get latest product
    ReadProduct(2);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const validationSchema = Yup.object({
    cessation_date: Yup.string().required('Resignation date is required'),
    cessation_reason: Yup.string().required(
      'Reason for Resignation is required',
    ),
  });

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11; // in cart status
    let res = await CreateCessationApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        type: 'Resignation of Director',
        price: product.price,
        quantity: 1,
        user_id: user.user_id,
        product_id: product.id,
        product_detail_id: res.body.applications.cessation_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  return (
    <>
      <AlertBox
        title="Item added to cart"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
        topButtonLabel="View your cart"
        botButtonLabel="Back to requests"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_RESGN_DIRECTOR);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change of Officers',
            route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
          },
          {
            path: 'Resignation of Director',
            route: ROUTES.CORPSEC_REQ_RESGN_DIRECTOR,
          },
          {
            path: 'Director Information',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Formik
        initialValues={{
          cessation_date: new Date(),
          cessation_reason: '',
          user_company_role_id: 5,
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                <FormDatePicker
                  color="secondary"
                  fullWidth
                  containerWidth="50%"
                  name="cessation_date"
                  labelText="Date of Resignation *"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                />
                <FormTextField
                  name="cessation_reason"
                  labelText="Reason for Resignation *"
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                  color="secondary"
                />
              </Box>
            </Paper>
            <Box pt={3} />
            <MzButton
              disabled={!(props.isValid && props.dirty)}
              type="submit"
              title="Submit"
              loading={requestSubmitting}
              onClick={() => {}} // must put onClick else cannot trigger formik
            />
          </Form>
        )}
      </Formik>
      <Box p={4} />
    </>
  );
};

export default ResgnDirectorInfo;
