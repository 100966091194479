import { FormControl, FormLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Field } from 'formik';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
}));

export function FormDropdown({ name, label, onChange, options, ...props }) {
  const classes = useStyles();
  const [val, setVal] = useState(props.value);

  const handleChange = (e) => {
    setVal(e.target.value);
    onChange(e.target.value);
  };
  return (
    <Field name={name} value={val}>
      {({ field, meta }) => {
        return (
          <>
            <FormControl
              component="fieldset"
              style={{
                float: 'left',
                marginBottom: 20,
                width: '100%',
                textAlign: 'start',
              }}
            >
              <FormLabel
                component="legend"
                style={{ textAlign: 'left', color: '#DEAD00' }}
              >
                {label}
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                value={val}
                label={label}
                onChange={handleChange}
              >
                {options.map((op, i) => (
                  <MenuItem value={op.value} key={i}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      }}
    </Field>
  );
}
