import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Typography, Box, Button, Paper } from '@material-ui/core';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
// import useStyles from './Requests.styles';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import { TAB_REQUESTS } from 'src/constants/corpsec';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ActionList from 'src/components/ActionList';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideUser } from 'src/hooks/useUsers';
import fourSteps from 'src/assets/requests/4steps.png';
import companySvc from 'src/services/company';

const ResgnAdmin = () => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const { smBelow } = useBreakpoints();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ListUserByCompanyRole, users } = useProvideUser();
  const company = companySvc.getCompany();

  useEffect(() => {
    ListUserByCompanyRole(company.companyId, 15);
  }, []);

  const handleOnclickBack = (e) => {
    e.preventDefault();
    if (step === 1) {
      history.push(ROUTES.CORPSEC, {
        search: `?tab=${TAB_REQUESTS.value}`,
      });
    }
  };

  return (
    <>
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change of Officers',
            route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
          },
          {
            path: 'Resignation of Administrator',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Resignation of Administrator
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Resignation of Active Administrator or Authorised Agent of your
                business
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                The name of the Administrator or Authorized Agent resigning.
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={fourSteps} height={50} />
          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 2 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 1-2 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />
          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <ActionList
          title="Select Administrator for Resignation"
          itemList={users}
          buttonLabel="Resign"
          onClick={(adminId) => {
            history.push(ROUTES.CORPSEC_REQ_RESGN_ADMIN_INFO(adminId));
          }}
          onClickBackButton={() => {
            history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
          }}
        />
      )}
      <Box py={5} />
    </>
  );
};

export default ResgnAdmin;
