import { createStyles, makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: '#161616',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },

    drawerOpen: {
      backgroundColor: '#161616',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: '#161616',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      height: '100vh',
    },
    textlabel: {
      color: '#FFFFFF',
    },
    textlabelclose: {
      color: '#161616',
    },
    icon: {
      minWidth: 40,
    },
    subtextlabel: {
      color: '#FFFFFF',
      fontSize: 15,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    selectedlabel: { backgroundColor: '#ABABAB !important' },
    settingLogo: {
      color: 'white',
      width: 20,
      height: 20,
      [theme.breakpoints.down('sm')]: {
        width: 35,
        height: 35,
      },
    },
    settingDrawer: {
      backgroundColor: '#161616',
    },
    settingsDropDown: {
      left: 0,
      right: 0,
      top: 50,
    },
  }),
);
