import React, { useEffect, useState } from 'react';
import ImageTitleList from '../components/ImageTitleList/ImageTitleList';
import { AllServices, Promotions, QuickLinks } from '../constants/list';
import Greeting from '../components/Greeting/Greeting';
import IconListBox from '../components/IconListBox/IconListBox';
import CardSlider from '../components/CardSlider/CardSlider';
import AuthLayout from 'src/components/AuthLayout';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import authSvc from 'src/services/authentication';

const Dashboard = () => {
  const history = useHistory();

  const [user, setUser] = useState('');

  useEffect(() => {
    const data = authSvc.getAuthData();
    setUser(data.user);
  }, []);
  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box padding={3}>
        <Greeting title={`${user.first_name} ${user.last_name}`} />
        <CardSlider itemData={Promotions} overlay />
        <IconListBox
          title="Quick Links"
          itemData={QuickLinks}
          styles={{ paddingTop: 50 }}
        />
        <ImageTitleList
          title="All Services"
          itemData={AllServices}
          overlay
          styles={{ paddingTop: 50, paddingBottom: 30 }}
          onClick={(item) => {
            if (item.route) {
              history.push(item.route);
            }
          }}
        />
      </Box>
    </AuthLayout>
  );
};

export default Dashboard;
