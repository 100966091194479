import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import companySvc from 'src/services/company';

const boardroomContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListBoardrooms: () => Promise.resolve({}),
});

export function useProvideBoardroom() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [boardrooms, setBoardrooms] = useState([]);

  const ListBoardrooms = async () => {
    const company = companySvc.getCompany();
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `https://api.meyzer360.com/company/companies/${company.companyId}/officers/?type=active`,
      method: 'get',
    });
    if (res.status === 'success') {
      setBoardrooms(res.body.userCompanyRoles);
    }
    return res;
  };

  return {
    error,
    loading,
    submitting,
    boardrooms,
    ListBoardrooms,
  };
}

export const useBoardroomContext = () => useContext(boardroomContext);

export function ProvideBoardroom({ children }) {
  const boardroom = useProvideBoardroom();
  return (
    <boardroomContext.Provider value={boardroom}>
      {children}
    </boardroomContext.Provider>
  );
}
