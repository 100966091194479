import {
  Box,
  Button,
  Container,
  Link,
  makeStyles,
  Toolbar,
  useTheme,
} from '@material-ui/core';
import mlogo from '../assets/m360.png';
import { Formik, Form } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormDropdown, FormRadio } from 'src/components/Input';
import authSvc from 'src/services/authentication.js';
import commonSvc from 'src/services/common.js';
import * as Yup from 'yup';
import FormCheckbox from '../components/FormCheckbox/FormCheckbox.js';
import FormDropdownTextField from '../components/FormDropdownTextField/FormDropdownTextField.js';
import FormTextField from '../components/FormTextField/FormTextField.js';
import IntroBar from '../components/IntroBar/IntroBar.js';
import { Countries } from '../constants/list';
import ROUTES from '../constants/routes';
import ProgressLoader from 'src/components/Progress';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    toolbar: theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 150,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#161616',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

function Signpage(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [msg, setMsg] = React.useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState('SG');
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    (async () => {
      const rs = await commonSvc.countries();
      setCountries(rs?.countries || []);
    })();
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string().required('Name is required'),
    first_name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string().required('Email is required'),
    confirmPassword: Yup.string()
      .required('Email is required')
      .equals(
        [Yup.ref('password')],
        'Confirm password must be equals password',
      ),
    mobileNum: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    // hearFrom: Yup.object()
    //   .shape({
    //     label: Yup.string(),
    //   })
    //   .nullable()
    //   .required('This field is required'),
    agree_tnc: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions',
    ),
  });

  const formikOnSubmit = async (values, actions) => {
    setLoading(true);
    setMsg('');
    let mobilePrefix = Countries.find((country) => country.code == countryCode);
    values.mobilePrefix = mobilePrefix.phone;
    authSvc.signup({ ...values }).then(
      (res) => {
        setLoading(true);
        history.push(ROUTES.SIGNINPAGE);
      },
      (err) => {
        setMsg(err.message);
        setLoading(false);
      },
    );
  };

  return (
    <Box className={classes.root}>
      <IntroBar
        title="Let’s get started"
        subTitle="We’re excited to have you here.  To get started, please enter your name, email address and phone number in the form on the right."
      />
      <Container className={classes.content}>
        <Toolbar className={classes.toolbar} />
        <Formik
          initialValues={{
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            mobileNum: '',
            password: '',
            gender_id: '1',
            nationality_id: '1',
            confirmPassword: '',
            // hearFrom: null,
            // referral: '',
            agree_tnc: false,
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Box
                style={{
                  padding: 30,
                  borderRadius: 25,
                  boxShadow: '0 0 10px #ccc',
                }}
              >
                <FormTextField
                  name="first_name"
                  icon="mdi:account"
                  labelText="First Name"
                  placeholder="Name must match exactly as listed in ID"
                  fullWidth
                  shrink
                  variant="standard"
                />

                <FormTextField
                  name="last_name"
                  icon="mdi:account"
                  labelText="Last Name"
                  placeholder="Name must match exactly as listed in ID"
                  fullWidth
                  shrink
                  variant="standard"
                />
                <FormRadio
                  name="gender_id"
                  label="Gender"
                  value="1"
                  options={[
                    { label: 'Male', value: '1' },
                    { label: 'Female', value: '2' },
                  ]}
                  placeholder="Name must match exactly as listed in ID"
                  fullWidth
                  shrink
                  onChange={(val) => {
                    props.values.gender_id = val;
                  }}
                />

                <FormTextField
                  name="username"
                  icon="mdi:account"
                  labelText="Username"
                  placeholder="username"
                  fullWidth
                  shrink
                  variant="standard"
                />

                <FormTextField
                  name="email"
                  icon="ic:round-email"
                  labelText="Email address"
                  placeholder="abc@example.com"
                  fullWidth
                  shrink
                  variant="standard"
                />
                <FormDropdownTextField
                  name="mobileNum"
                  icon="clarity:mobile-phone-solid"
                  labelText="Mobile number"
                  placeholder="91234567"
                  fullWidth
                  shrink
                  variant="standard"
                  onValueChange={(event, newValue) => {
                    setCountryCode(newValue.code);
                  }}
                  countryCode={countryCode}
                />

                <FormDropdown
                  name="nationality_id"
                  icon="clarity:mobile-phone-solid"
                  label="Nationality"
                  placeholder="Country"
                  fullWidth
                  shrink
                  variant="standard"
                  value=""
                  options={[
                    ...countries.map((c) => ({
                      label: c.country_name,
                      value: c.country_id,
                    })),
                  ]}
                  onChange={(val) => {
                    props.values.nationality_id = val;
                  }}
                />

                <FormTextField
                  name="password"
                  icon="mdi:lock"
                  labelText="Password"
                  placeholder="******"
                  type="password"
                  fullWidth
                  shrink
                  variant="standard"
                />
                <FormTextField
                  name="confirmPassword"
                  icon="mdi:lock"
                  labelText="Confirm Password"
                  placeholder="******"
                  type="password"
                  fullWidth
                  shrink
                  variant="standard"
                />
                {/* <FormDropdown
                  name="hearFrom"
                  onChange={(event, newValue) => {
                    props.setFieldValue('hearFrom', newValue);
                  }}
                  optionList={OptionList}
                  icon="emojione-monotone:speaker-medium-volume"
                  labelText="Where did you hear from us?"
                  placeholder="Type to Search or Select any of the following"
                  fullWidth
                  shrink
                  variant="standard"
                />
                <FormTextField
                  name="referral"
                  icon="bx:bxs-discount"
                  labelText="Referral/promo code"
                  optional
                  placeholder="Enter the referral code or promo code"
                  fullWidth
                  shrink
                  variant="standard"
                /> */}

                <FormCheckbox
                  name="agree_tnc"
                  label={
                    <span>
                      I have read and agreed with the{' '}
                      <Link
                        rel="noopener noreferrer"
                        href="https://main.d1pjx2fjy1j29l.amplifyapp.com/"
                        target="_blank"
                        style={{
                          fontWeight: 600,
                          color: '#DEAD00',
                        }}
                      >
                        Terms and Conditions
                      </Link>
                      ,{' '}
                      <Link
                        rel="noopener noreferrer"
                        href="https://main.d1pjx2fjy1j29l.amplifyapp.com/"
                        target="_blank"
                        style={{
                          fontWeight: 600,
                          color: '#DEAD00',
                        }}
                      >
                        Use Policy
                      </Link>
                      , and{' '}
                      <Link
                        rel="noopener noreferrer"
                        href="https://main.d1pjx2fjy1j29l.amplifyapp.com/"
                        target="_blank"
                        style={{
                          fontWeight: 600,
                          color: '#DEAD00',
                        }}
                      >
                        Privacy Policy
                      </Link>
                      .
                    </span>
                  }
                />
              </Box>
              <Box
                display="flex"
                style={{ paddingTop: 40, alignItems: 'left' }}
              >
                <p>{msg}</p>
                <ProgressLoader loading={loading} />
                <div style={{ flexGrow: 1 }} />
                <Button
                  color="primary"
                  disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: !(props.isValid && props.dirty)
                      ? null
                      : '#DEAD00',
                    color: '#FFFFFF',
                    borderRadius: 50,
                    minHeight: 50,
                    minWidth: 230,
                    textTransform: 'initial',
                    fontWeight: 600,
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
}

export default Signpage;
