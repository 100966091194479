import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './Greeting.styles';

function Greeting({ title }) {
  const classes = useStyles();

  //   const [size] = useState(props.size);
  return (
    <Typography variant="h5" align="left" className={classes.mainTitle}>
      Hi, {title}
    </Typography>
  );
}
export default Greeting;
