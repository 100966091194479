import React, { useState, useEffect, useContext } from 'react';
import logo from '../assets/homepage/confirmation.png';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  ButtonBase,
  AppBar,
  Toolbar,
  Badge,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { CorpsecNameBox } from 'src/components/Corpsec';
import AuthLayout from 'src/components/AuthLayout';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import moment from 'moment';
import useButtonStyle from 'src/styles/button';
import { useProvideCheckout } from 'src/hooks/useCheckoutIntegration';
import { useProvideOrder } from 'src/hooks/useOrder';
import { useProvideWebDev } from 'src/hooks/useWebDev';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const PaymentCart = () => {
  const classes = useStyles();
  const history = useHistory();
  const { xsBelow } = useBreakpoints();
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [totalPrice, calTotalPrice] = useState(0);
  const { cartList, cart, removeCart, webInfo, user, fetchCart } = useContext(
    ConfigContext,
  );
  const {
    PaymentByLink,
    checkoutError,
    checkoutSubmitting,
  } = useProvideCheckout();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const buttonClasses = useButtonStyle();
  const { AddWebDev } = useProvideWebDev();
  const { CreateOrder, orderError, orderSubmitting } = useProvideOrder();

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < cartList.length; i++) {
      total += Number(cartList[i].price);
    }
    calTotalPrice(total);
  }, [JSON.stringify(cartList)]);

  useEffect(() => {
    if (checkoutError) {
      showErrorSnackbar(`Checkout failed. ${checkoutError}`);
    }
  }, [checkoutError]);

  useEffect(() => {
    if (orderError) {
      showErrorSnackbar(`Checkout failed. ${orderError}`);
    }
  }, [orderError]);

  const postPaymentFlow = async () => {
    if (webInfo.length !== 0) {
      await AddWebDev(webInfo);
    }
  };

  const formikOnSubmit = async (values, actions) => {
    const products = cartList.map((item) => {
      return {
        name: item.product.name,
        price: item.price * 100,
        quantity: item.quantity,
      };
    });
    if (!cart || !cart.id) {
      showErrorSnackbar(
        `Problem checking out your cart. Please refresh and try again.`,
      );
    }

    if (totalPrice === 0) {
      const res = await CreateOrder({ user_id: user.user_id });
      if (res.status === 'success') {
        fetchCart(user);
        showSuccessSnackbar('Order submitted');
        history.push(ROUTES.ORDERHISTORY);
      }
      return;
    }

    // request payment link
    const res = await PaymentByLink({
      billing: {
        address: {
          country: 'SG',
        },
      },
      products,
      currency: 'SGD',
      amount: totalPrice * 100,
      reference: String(cart.id),
      return_url: `${window.location.origin}/orderhistory?redirect=checkout`,
    });
    if (res.status === 'success') {
      showSuccessSnackbar('Redirecting to payment...');
      window.location.replace(res.body._links.redirect.href);
    }
  };

  const renderStep1 = (totalPrice) => {
    return (
      <Box
        style={{
          width: '100%',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h6"
            align="left"
            color="secondary"
            style={{
              fontWeight: 600,
            }}
          >
            Cart
          </Typography>

          {cartList.length !== 0 &&
            cartList.map((item, index) => (
              <>
                <Box pt={3} />

                <Badge
                  badgeContent={'X'}
                  color="error"
                  onClick={() => {
                    removeCart(cartList, index);
                  }}
                  classes={{
                    badge: buttonClasses.badgeSizeLarge,
                  }}
                  style={{ cursor: 'pointer' }}
                />
                <Box
                  key={`${item.type}${index}`}
                  style={{
                    padding: 30,
                    borderRadius: 25,
                    boxShadow: '0 0 10px #ccc',
                    width: '-webkit-fill-available',
                  }}
                >
                  <Box
                    display={xsBelow ? 'initial' : 'flex'}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      align="left"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      align={'right'}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      S$ {item.price}
                    </Typography>
                  </Box>

                  <Box display={xsBelow ? 'initial' : 'flex'}>
                    <Typography variant="body1" align="left">
                      Date: {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                  </Box>
                </Box>
              </>
            ))}

          <Box pt={15} />
          <Box
            style={{
              padding: 30,
              borderRadius: 25,
              boxShadow: '0 0 10px #ccc',
            }}
          >
            <Box display="flex">
              <Typography
                variant="body1"
                align="left"
                style={{
                  fontWeight: 600,
                }}
              >
                Total
              </Typography>
              <Box pr={5} />
              <Typography
                variant="body1"
                align="right"
                style={{
                  fontWeight: 600,
                }}
              >
                S$ {totalPrice}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box pt={4} />
        <Box display="flex" height={60}>
          <MzButton
            title="PROCEED TO PAYMENT"
            classes={{
              root: classes.button,
            }}
            onClick={() => setStep(2)}
          />
          <Box pr={3} />
          <MzButton
            title="CONTINUE SHOPPING"
            classes={{
              root: classes.button,
            }}
            onClick={() => history.push(ROUTES.DASHBOARD)}
          />
        </Box>
        <Box pb={3} />
      </Box>
    );
  };

  const renderStep2 = (totalPrice) => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(222, 173, 0, 1)',
          }}
          onClick={() => {
            setStep(1);
            setPaymentMethod('');
          }}
        >
          <Icon icon="eva:arrow-ios-back-fill" width="20" height="20" />
          <Typography
            variant="body1"
            align="left"
            style={{
              fontWeight: 600,
            }}
          >
            Back
          </Typography>
        </Box>
        <Box pt={3} />
        <Typography
          variant="h6"
          align="left"
          color="secondary"
          style={{
            fontWeight: 600,
          }}
        >
          Order Summary
        </Typography>
        <Box pt={2} />
        <Box
          style={{
            padding: 30,
            borderRadius: 25,
            boxShadow: '0 0 10px #ccc',
            width: '100%',
          }}
        >
          <Box display="flex">
            <Typography
              variant="body1"
              style={{
                fontWeight: 600,
              }}
            >
              Total
            </Typography>
            <Box pr={5} />
            <Typography
              variant="body1"
              style={{
                fontWeight: 600,
              }}
            >
              S$ {totalPrice}
            </Typography>
          </Box>
        </Box>
        <Box pt={5} />
        <Formik
          initialValues={
            {
              // email: '',
              // cardExpiry: new Date(),
            }
          }
          onSubmit={formikOnSubmit}
          // validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Typography
                variant="body1"
                align="left"
                color="secondary"
                className={classes.fontWeight}
              >
                Select Payment Method
              </Typography>
              <Box display="flex" height={60}>
                <Button
                  variant="outlined"
                  className={
                    paymentMethod === 'paynow'
                      ? classes.paymentActiveButton
                      : classes.paymentButton
                  }
                  onClick={() => setPaymentMethod('paynow')}
                >
                  <Typography variant="body1" className={classes.fontWeight}>
                    PayNow
                  </Typography>
                </Button>
                <Box pr={3} />
                <Button
                  variant="outlined"
                  className={
                    paymentMethod === 'card'
                      ? classes.paymentActiveButton
                      : classes.paymentButton
                  }
                  onClick={() => setPaymentMethod('card')}
                >
                  <Typography variant="body1" className={classes.fontWeight}>
                    Card
                  </Typography>
                </Button>
              </Box>

              <Box
                pt={5}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                {paymentMethod === 'card' && (
                  <>
                    <MzButton
                      loading={checkoutSubmitting || orderSubmitting}
                      // disabled={!(props.isValid && props.dirty)}
                      title={'Pay $' + totalPrice}
                      size="small"
                      style={{ width: 180 }}
                      onClick={() => {
                        formikOnSubmit();
                      }}
                    />
                    <Box pb={2} />
                  </>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        <ButtonBase
          type="submit"
          variant="contained"
          className={classes.gradientButton}
          onClick={() => {
            setStep(1);
            setPaymentMethod('');
          }}
        >
          <Typography variant="body1" className={classes.fontWeight}>
            CANCEL PAYMENT
          </Typography>
        </ButtonBase>
        <Box pb={4} />
      </>
    );
  };

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        {/* <AppBar
          position="static"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h5" noWrap>
              CORPORATE SECRETARY
            </Typography>
          </Toolbar>
        </AppBar> */}

        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          className={classes.content}
        >
          <Container>
            {/* <CorpsecNameBox /> */}
            <Box
              pt={3}
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
            >
              {step === 1 && renderStep1(totalPrice)}
              {step === 2 && renderStep2(totalPrice)}
            </Box>
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default PaymentCart;
