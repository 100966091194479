import React from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './FileDropzone.styles';
import { Button, Typography, Box } from '@material-ui/core';

const FileDropzone = ({ multiple = false, files = [], setFiles, ...props }) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple,
  });

  useEffect(() => {
    if (setFiles) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  function bytesToSize(bytes) {
    const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte'];
    const unit = Math.floor(Math.log(bytes) / Math.log(1024));
    return new Intl.NumberFormat('en', {
      style: 'unit',
      unit: units[unit],
    }).format(bytes / 1024 ** unit);
  }

  return (
    <>
      <section className={classes.container}>
        {files.length === 0 && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag & drop here</p>
            <em>or click to Upload</em>
          </div>
        )}
        {files.length > 0 && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight={'50px'}
            flexShrink={1}
          >
            <Typography variant="overline">{files[0].name}</Typography>
            <Typography variant="overline">
              {bytesToSize(files[0].size)}
            </Typography>
            <Button
              variant="outlined"
              style={{
                color: '#900E1A',
                border: '1px solid #900E1A',
              }}
              onClick={() => {
                if (setFiles) {
                  setFiles([]);
                }
              }}
            >
              Remove
            </Button>
          </Box>
        )}
      </section>
    </>
  );
};

export default FileDropzone;
