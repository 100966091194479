import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import companySvc from 'src/services/company';

const captableContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListCapTables: () => Promise.resolve({}),
});

export function useProvideCapTable() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [capTables, setCapTables] = useState([]);

  const ListCapTables = async () => {
    const company = companySvc.getCompany();
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `https://3o9368clw8.execute-api.ap-southeast-1.amazonaws.com/Prod/companies/${company.companyId}/shareholders`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCapTables(res.body.shares);
    }
    return res;
  };

  return {
    error,
    loading,
    submitting,
    capTables,
    ListCapTables,
  };
}

export const useCapTableContext = () => useContext(captableContext);

export function ProvideCapTable({ children }) {
  const captable = useProvideCapTable();
  return (
    <captableContext.Provider value={captable}>
      {children}
    </captableContext.Provider>
  );
}
