import companySvc from './company';
import axios from './customAxios';

import helper from 'src/helper';

const timeline = async () => {
  const company = companySvc.getCompany();
  const { data } = await axios.get(
    `https://api.meyzer360.com/company/timeline/company/${company.companyId}`,
  );

  if (data && !data.is_success) throw Error(data?.message);

  return helper.isEmpty(data?.timeline) ? [] : data?.timeline;
};

const timelineSvc = { timeline };

export default timelineSvc;
