import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { useProvideShareholder } from 'src/hooks/useShareholders';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import { Icon } from '@iconify/react';
import companySvc from 'src/services/company';
import { ConfigContext } from 'src/contexts';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import useSnackbar from 'src/hooks/useSnackbar';
import { StringMasking } from 'src/utils/string';

const tempSteps = [
  {
    id: 1,
    label: 'Transferor',
  },
  {
    id: 2,
    label: 'Transferee',
  },
];

const currencyList = [
  { label: 'EUR' },
  { label: 'GBP' },
  { label: 'JPY' },
  { label: 'SGD' },
  { label: 'USD' },
];

const shareholderList = [
  { label: 'Gideon' },
  { label: 'Edison' },
  { label: 'Tan' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: 'black',
    display: 'flex',
    alignItems: 'left',
    textAlign: 'left',
    backgroundColor: '#ECECEC',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const TransferShares = () => {
  const history = useHistory();
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(0);
  const [shareToTransfer, setShareToTransfer] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [countryCode, setCountryCode] = useState('SG');
  // const [isNewShareholder, setIsNewShareholder] = useState(false);
  const { addCart, user, cartSubmitting } = useContext(ConfigContext);
  const company = companySvc.getCompany();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    GetShareholderByUserID,
    shareholderError,
    shareholder,
    shareholders,
    newshareholder,
    ListShareholders,
    ApplyNewShareholder,
  } = useProvideShareholder();
  const {
    CreateShareTransferApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();

  useEffect(() => {
    // get latest product
    ReadProduct(21);
    GetShareholderByUserID(user.user_id);
    ListShareholders(company.companyId);
  }, []);

  // console.log('@@@ isNewShareholder', isNewShareholder);

  // useEffect(() => {
  //   if (newshareholder) {
  //     console.log('@@@ inside');
  //     setIsNewShareholder(!newshareholder);
  //   }
  // }, [newshareholder]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (shareholderError) {
      showErrorSnackbar(`Error loading shareholder info. ${shareholderError}`);
    }
  }, [shareholderError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getShareholderByCompany = (shareholders = []) => {
    return shareholders.find((sh) => sh.company_id === company.companyId);
  };

  const step2validationSchema = Yup.object({
    number_of_share_transfer: Yup.number()
      .max(
        getShareholderByCompany(shareholder)?.user_allotment || 0,
        'Cannot be more than your share',
      )
      .required('Shares to Transfer is required'),
  });

  // console.log('@@@ newshareholder', !!newshareholder);
  const step3validationSchema = Yup.object({
    // transfer_date: Yup.string().required('Transfer date is required'),
    // transfer_to_user_id: Yup.string().required('Shareholder is required'),
    // transfer_to_user_id: !!newshareholder
    //   ? Yup.string()
    //   : Yup.string().required('Shareholder is required'),
    // transfer_to_user_id: Yup.string().when('isNewShareholder', {
    //   is: () => false,
    //   then: Yup.string().required('Shareholder is required'),
    // }),
    // transfer_to_user_id: Yup.string().when('newshareholder', {
    //   is: () => !!newshareholder,
    //   then: Yup.string().required('Shareholder is required'),
    // }),
  });

  const step4validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
  });

  const setShareholderOptions = (shareholders = []) => {
    return shareholders.map((sh) => ({
      label: sh.Name,
      value: sh.user_id,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    values.transfer_from_shareholder_id = getShareholderByCompany(
      shareholder,
    )?.shareholder_id;
    values.number_of_share_transfer = shareToTransfer;
    values.is_new_shareholder = newshareholder ? 1 : 0;

    let res = await CreateShareTransferApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.share_transfer_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const formikOnSubmitNewShareholder = async (values, actions) => {
    let res = await ApplyNewShareholder({
      company_id: company.companyId,
      company_application_status_id: 11,
      is_foreigner: tabValue == 0 ? 0 : 1,
      shareholder_name: values.name,
      identity_id: values.nric_passport_no,
      mobile_number: values.mobile_number,
      email: values.email,
      proof_identity_attachment_id: null,
      passport_attachment_id: null,
      proof_residency_attachment_id: null,
      created_by: 1,
    });
    if (res.status === 'success') {
      actions.resetForm();
      setStep(2);
    }
  };

  const FormRow = ({ label, latest, balance }) => {
    return (
      <>
        <Grid item xs={6}>
          <Paper className={classes.paper}>{latest}</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>{balance}</Paper>
        </Grid>
      </>
    );
  };

  const stepOne = () => {
    return (
      <>
        <Typography variant="h6" color="secondary">
          Transfer of Shares
        </Typography>
        <Box p={1} />
        <Typography variant="h6" color="secondary">
          What can I do in this request?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Transfer of Ordinary Shares from existing shareholder to other
              existing or new <b>Individual</b> shareholders
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              For transfer of other share type and currency, please submit under
              Other Request
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What information / document(s) do I need?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Shareholder particulars & copy of NRIC/ Passport (For New
              Shareholder)
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Transfer Details (Number of Ordinary Shares & Considerations for
              each shareholder)
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What are the steps?
        </Typography>
        <img src={fourSteps} height={50} />
        <Typography variant="h6" color="secondary">
          How much does it cost?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Basic Plan: SGD100
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Unlimited Plan: SGD100
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          How long does it take?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Time to fill form: {'<'} 5 min
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Average Processing Time: 10-15 days
            </Typography>
          </li>
        </ul>
        <Box p={1} />

        <MzButton
          title={'Lets get started'}
          onClick={() => {
            setStep(1);
          }}
        />
      </>
    );
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          currency: '',
          existingshares: '',
          transfershares: '',
        }}
        // onSubmit={formikOnSubmit}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                {/* <FormDropdown
                  autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
                  name="currency"
                  onChange={(event, newValue) => {
                    props.setFieldValue('currency', newValue?.label);
                  }}
                  optionList={currencyList}
                  labelText="Select Currency"
                  placeholder="Select Currency"
                  fullWidth
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                  defaultValue={currencyList[3]}
                /> */}

                <Box display="flex">
                  <Icon
                    icon="carbon:user-avatar-filled"
                    color="black"
                    width={'40'}
                    height={'40'}
                  />
                  <Box p={1} />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" align="left">
                      {user.first_name} {user.last_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      align="left"
                      style={{ opacity: '50%' }}
                    >
                      {user?.nric && StringMasking(`${user?.nric}`)}
                    </Typography>
                  </Box>
                </Box>

                <Box p={1} />
                <Box display="flex">
                  <FormTextField
                    name="existingshares"
                    disabled
                    value={getShareholderByCompany(shareholder)?.user_allotment}
                    labelText="Existing No. of Shares"
                    variant="outlined"
                  />
                  <Box p={1} />
                  <FormTextField
                    name="number_of_share_transfer"
                    labelText="Shares to Transfer"
                    variant="outlined"
                    type="number"
                  />
                </Box>
              </Box>
            </Paper>
            <Box p={2} />
            <Box display="flex" alignItems="flex-start">
              <MzButton
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {
                  setShareToTransfer(props.values.number_of_share_transfer);
                  setStep(2);
                }}
                title={'Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <Formik
        initialValues={{
          transfer_to_user_id: '',
          transfer_date: new Date(),
        }}
        onSubmit={formikOnSubmit}
        validationSchema={step3validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                {/* <DetailLabel label="Currency" value="Singapore Dollars" />
                <Box p={2} /> */}

                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Transfer from
                </Typography>
                <Box p={1} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Box display="flex">
                    <Icon
                      icon="carbon:user-avatar-filled"
                      color="black"
                      width={'40'}
                      height={'40'}
                    />
                    <Box p={1} />
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1" align="left">
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        align="left"
                        style={{ opacity: '50%' }}
                      >
                        {user?.nric && StringMasking(`${user?.nric}`)}{' '}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="caption"
                      align="right"
                      style={{ opacity: '50%' }}
                    >
                      EXISTING NO. OF SHARES
                    </Typography>
                    <Typography variant="body1" align="right">
                      {getShareholderByCompany(shareholder)?.user_allotment}
                    </Typography>
                  </Box>
                </Box>
                <Box p={1} />
                <>
                  <Grid container spacing={1}>
                    <React.Fragment>
                      <Grid item xs={6}>
                        <Typography variant="body2" align="left">
                          SHARES TO TRANSFER
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" align="left">
                          BALANCE
                        </Typography>
                      </Grid>
                    </React.Fragment>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        latest={shareToTransfer}
                        balance={
                          getShareholderByCompany(shareholder)?.user_allotment -
                          shareToTransfer
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              </Box>
            </Paper>
            <Box
              p={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h5" className={classes.fontWeight}>
                Transfer Details
              </Typography>
              <MzButton
                title={'ADD NEW SHAREHOLDER'}
                onClick={() => {
                  setStep(3);
                }}
              />
            </Box>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                {newshareholder && (
                  <>
                    <DetailLabel
                      label="New Shareholder"
                      value={newshareholder.shareholder_name}
                    />
                    <Box p={1} />
                  </>
                )}
                {!newshareholder && (
                  <FormDropdown
                    autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
                    name="transfer_to_user_id"
                    onChange={(event, newValue) => {
                      props.setFieldValue(
                        'transfer_to_user_id',
                        newValue?.value,
                      );
                    }}
                    optionList={setShareholderOptions(shareholders)}
                    labelText="Select Shareholder"
                    placeholder="Select One"
                    fullWidth
                    containerWidth="50%"
                    shrink
                    variant="outlined"
                  />
                )}
                <FormDatePicker
                  containerWidth="50%"
                  color="secondary"
                  name="transfer_date"
                  labelText="Date of Transfer "
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                />
              </Box>
            </Paper>
            <Box p={2} />
            <Box display="flex" alignItems="flex-start">
              <MzButton
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
                title={'Submit'}
                loading={cartSubmitting || requestSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepFour = () => {
    return (
      <>
        <Typography variant="h6" align="left" className={classes.fontWeight}>
          Fill in information for New Shareholder
        </Typography>
        <Box p={1} />
        <Paper square>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Local" />
            <Divider orientation="vertical" flexItem />
            <Tab label="Foreigner" />
          </Tabs>
        </Paper>
        <Box p={1} />
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box p={3}>
            <Box p={2} />
            <TabPanel value={tabValue} index={0}>
              {renderForm()}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {renderForm()}
            </TabPanel>
          </Box>
        </Paper>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmitNewShareholder}
        validationSchema={step4validationSchema}
      >
        {(props) => (
          <Form>
            <FormTextField
              name="name"
              labelText="NAME (as in NRIC/FIN) *"
              placeholder="Name"
              shrink
              color="secondary"
              variant="outlined"
            />
            <FormTextField
              name="nric_passport_no"
              labelText="ID *"
              placeholder="ID"
              color="secondary"
              shrink
              variant="outlined"
            />
            <FormDropdownTextField
              name="mobile_number"
              labelText="Mobile Number *"
              placeholder="91234567"
              color="secondary"
              containerWidth="50%"
              fullWidth
              shrink
              variant="outlined"
              showFlag={false}
              onValueChange={(event, newValue) => {
                setCountryCode(newValue.code);
              }}
              countryCode={countryCode}
            />
            <FormTextField
              color="secondary"
              name="email"
              labelText="Email Address *"
              placeholder="Email Address"
              shrink
              variant="outlined"
            />
            {tabValue === 0 && (
              <FormFileDropzone
                containerWidth="50%"
                name="identityFiles"
                labelText="Proof of Identity *"
              />
            )}

            {tabValue === 2 && (
              <Box
                display="flex"
                flexDirection={smBelow ? 'column' : 'row'}
                style={{
                  gap: '20px',
                }}
              >
                <FormFileDropzone
                  containerWidth="50%"
                  name="passportFiles"
                  labelText="Copy of Passport *"
                />
                <FormFileDropzone
                  containerWidth="50%"
                  name="residencyFiles"
                  labelText="Proof of Residency (Must be in English) *"
                />
              </Box>
            )}

            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                title={'SAVE'}
                onClick={() => {}}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <AlertBox
        title="Request successfully added to cart!"
        isVisible={showAlert}
        topButtonLabel="Add More"
        topButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION);
        }}
        topButtonStyles={{
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #000000',
        }}
        botButtonLabel="Go to cart"
        botButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonStyles={{
          color: '#FFFFFF',
          backgroundColor: '#FFB800',
        }}
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Share Information',
            route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
          },
          {
            path: 'Transfer of Shares',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />

      {(step === 1 || step === 2) && (
        <Stepper
          steps={tempSteps}
          providedStep={step - 1}
          setProvidedStep={(step) => {
            setStep(step + 1);
          }}
        />
      )}
      <Box
        flexDirection="column"
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ width: '100%' }}
      >
        {step === 0 && stepOne()}
        {step === 1 && stepTwo()}
        {step === 2 && stepThree()}
        {step === 3 && stepFour()}
      </Box>
      <Box p={4} />
    </>
  );
};

export default TransferShares;
