import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    button: {
      backgroundImage:
        'linear-gradient(270deg, #760000 1.54%, #FFB800 101.02%);',
    },
  }),
);
