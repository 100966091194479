import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideProduct } from 'src/hooks/useProduct';
import { useProvideCompany } from 'src/hooks/useCompany';
import AlertBox from 'src/components/AlertBox';
import companySvc from 'src/services/company';
import { FormDatePicker } from 'src/components/Form';
import moment from 'moment';

const ChangeCompanyFinancialYearEnd = () => {
  // const classes = useStyles();
  const company = companySvc.getCompany();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [showAlert, setShowAlert] = useState(false);

  const {
    CreateFinancialYearEndApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const {
    GetFinancialYearEnd,
    GetAGM,
    agm,
    financialYearEnd,
    companyError,
  } = useProvideCompany();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  const validationSchema = Yup.object({
    new_financial_year_end_date: Yup.string().required(
      'Financial Year End is required',
    ),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(13);
    GetFinancialYearEnd(company.companyId);
    GetAGM(company.companyId);
  }, []);

  useEffect(() => {
    if (companyError) {
      showErrorSnackbar(
        `Error loading Company's financial year end. ${companyError}`,
      );
    }
  }, [companyError]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11;
    values.created_by = user.user_id;
    let res = await CreateFinancialYearEndApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id:
          res.body.applications[0].financial_year_end_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const renderForm = () => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
        elevation={4}
      >
        <Formik
          initialValues={{
            new_financial_year_end_date: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Box
                p={3}
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Typography variant="caption">
                  Financial Year End Date
                </Typography>
                <Typography variant="h6">{`${moment(
                  financialYearEnd?.event_datetime,
                ).format('DD/MM/YYYY')}`}</Typography>
                <Box py={1} />
                <Typography variant="caption">Next AGM Due Date</Typography>
                <Typography variant="h6">{`${moment(agm?.event_datetime).format(
                  'DD/MM/YYYY',
                )}`}</Typography>
                <Box py={1} />
                <FormDatePicker
                  color="secondary"
                  name="new_financial_year_end_date"
                  labelText="New Financial Year End *"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                />
                <MzButton
                  disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  title={'SUBMIT'}
                  onClick={() => {}}
                  loading={requestSubmitting}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  };

  return (
    <>
      <AlertBox
        title="Item added to cart"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
        topButtonLabel="View your cart"
        botButtonLabel="Back to requests"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Company Particulars',
            route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
          },
          {
            path: 'Change Company Financial Year End',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Change Company Financial Year End
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Change the Financial Year End for our business
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                New Financial Year End for the business
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={fourSteps} height={50} />
          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Basic Plan: SGD50
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Unlimited Plan: Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 2 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 3 - 5 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />
          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <>
          <Typography variant="h6" color="secondary">
            Change Company Financial Year End
          </Typography>
          <Box py={2} />
          {renderForm()}
        </>
      )}
      <Box py={5} />
    </>
  );
};

export default ChangeCompanyFinancialYearEnd;
