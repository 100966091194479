import React, { useState, useEffect } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  ButtonBase,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { CorpsecNameBox } from 'src/components/Corpsec';
import AuthLayout from 'src/components/AuthLayout';
import DetailLabel from 'src/components/DetailLabel';
import { useProvideOrder } from 'src/hooks/useOrder';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const OrderHistoryDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { xsBelow } = useBreakpoints();
  const { ReadOrder, order } = useProvideOrder();
  const params = useParams();

  useEffect(() => {
    ReadOrder(params.id);
  }, []);

  const TypographyStatus = ({ status }) => {
    let statusColor, textLabel;
    switch (status) {
      case 'completed':
        statusColor = '#24FF00';
        textLabel = 'Completed';
        break;
      case 'PENDING':
        statusColor = '#FF7F09';
        textLabel = 'Pending';
        break;
      case 'paid':
        statusColor = '#041DFF';
        textLabel = 'Paid';
        break;
      case 'processing':
        statusColor = '#FF7F09';
        textLabel = 'Processing';
        break;
      case 'failed':
        statusColor = '#FF4D00';
        textLabel = 'Failed';
        break;
    }
    return (
      <Typography
        variant="body1"
        align="right"
        style={{ fontWeight: 600, color: statusColor }}
      >
        {textLabel}
      </Typography>
    );
  };

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        {/* <AppBar
          position="static"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h5" noWrap>
              CORPORATE SECRETARY
            </Typography>
          </Toolbar>
        </AppBar> */}

        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          className={classes.content}
        >
          <Container>
            {/* <CorpsecNameBox /> */}
            <Box pt={3} display="flex" flexDirection="column">
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'rgba(222, 173, 0, 1)',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  history.push(ROUTES.ORDERHISTORY);
                }}
              >
                <Icon icon="eva:arrow-ios-back-fill" width="20" height="20" />
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Back
                </Typography>
              </Box>
              <Box pt={3} />

              <Typography
                variant="h6"
                align="left"
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
              >
                Order Details
              </Typography>
              <Box pt={3} />
              <Box
                style={{
                  padding: 30,
                  borderRadius: 25,
                  boxShadow: '0 0 10px #ccc',
                  width: '100%',
                }}
              >
                <Box
                  display={xsBelow ? 'initial' : 'flex'}
                  justifyContent="space-between"
                >
                  <Box display={xsBelow ? 'initial' : 'flex'}>
                    <Typography
                      variant="body1"
                      align="left"
                      style={{ fontWeight: 'bold' }}
                    >
                      # {order.id}
                    </Typography>
                    <Box pr={4} />
                    <Typography variant="body1" align="left">
                      Date: {moment(order.created_at).format(`DD/MM/yyyy`)}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    align={'right'}
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    S$ {order.totalPrice}
                  </Typography>
                </Box>

                <Box
                  display={xsBelow ? 'initial' : 'flex'}
                  justifyContent="space-between"
                >
                  <TypographyStatus status={order.status} />
                </Box>
              </Box>
              <Box pb={4} />
              <Typography
                variant="h6"
                align="left"
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
              >
                {order?.items?.length || 0} Item(s)
              </Typography>
              <Box pt={3} />
              {order?.items?.map((item, index) => (
                <>
                  <Box
                    style={{
                      padding: 30,
                      borderRadius: 25,
                      boxShadow: '0 0 10px #ccc',
                      width: '100%',
                    }}
                    key={item.id}
                  >
                    <Box
                      display={xsBelow ? 'initial' : 'flex'}
                      justifyContent="space-between"
                    >
                      <Box display={xsBelow ? 'initial' : 'flex'}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: 'bold' }}
                        >
                          {index + 1}. {item.product?.name}
                        </Typography>
                        <Box pr={4} />
                        <Typography variant="body1" align="left">
                          Date: {moment(item.created_at).format(`DD/MM/yyyy`)}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        align={'right'}
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        S$ {item.price}
                      </Typography>
                    </Box>

                    <Box
                      display={xsBelow ? 'initial' : 'flex'}
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body1"
                        align="left"
                        style={{ fontWeight: 'bold' }}
                      >
                        # {item.id}
                      </Typography>
                      <Typography variant="body1" align="left">
                        Quantity: {item.quantity}
                      </Typography>
                    </Box>
                  </Box>
                  <Box pt={3} />
                </>
              ))}
            </Box>
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default OrderHistoryDetails;
