import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const userContext = createContext({
  users: [],
  userError: '',
  userLoading: false,
  userSubmitting: false,
  ListUserByCompanyRole: () => Promise.resolve({}),
  GetUserByID: () => Promise.resolve({}),
  GetUserContact: () => Promise.resolve({}),
});

export function useProvideUser() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [userContacts, setUserContacts] = useState([]);
  const [userError, setUserError] = useState();
  const [userLoading, setUserLoading] = useState(false);
  const [userSubmitting, setUserSubmitting] = useState(false);

  const GetUserContact = async (userID) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${Config.userURL}/users/${userID}/contacts`,
      method: 'get',
    });
    if (res.status === 'success') {
      setUserContacts(res.body.contacts);
    }
    return res;
  };

  const GetUserByID = async (userID) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${Config.userURL}/users/${userID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setUser(res.body.users);
    }
    return res;
  };

  const ListUserByCompanyRole = async (companyID = 1, roleID) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${Config.userURL}/users/companyrole/${companyID}/${roleID}/`,
      method: 'get',
    });
    if (res.status === 'success') {
      setUsers(res.body.users);
    }
    return res;
  };

  return {
    user,
    users,
    userContacts,
    userError,
    userLoading,
    userSubmitting,
    GetUserByID,
    GetUserContact,
    ListUserByCompanyRole,
  };
}

export const useUserContext = () => useContext(userContext);

export function ProvideUser({ children }) {
  const user = useProvideUser();
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
}
