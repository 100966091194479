import { Box, Button, Container, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import mlogo from '../assets/m360.png';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import FormTextField from '../components/FormTextField/FormTextField.js';
import ROUTES from '../constants/routes';
import authSvc from '../services/authentication';
import ProgressLoader from '../components/Progress';
import { ConfigContext } from 'src/contexts';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#161616',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

function Signinpage(props) {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [msg, setMsg] = React.useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { setUser } = useContext(ConfigContext);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formikOnSubmit = async (values, actions) => {
    setLoading(true);
    setMsg('');
    await authSvc.signin(values).then(
      (rs) => {
        // if (rs.is_success === true) {
        setUser(rs.user);
        // }

        setLoading(false);
        history.push(ROUTES.DASHBOARD);
      },
      (err) => {
        setMsg(err.message);
        setLoading(false);
      },
    );
  };

  const drawer = (
    <div>
      <Container style={{ paddingTop: 30 }}>
        <img src={mlogo} height={30} style={{ display: 'flex' }} />
        <Typography
          variant="h6"
          align="left"
          style={{
            color: '#FFFFFF',
            fontWeight: 600,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          Let’s get started
        </Typography>
        <Typography variant="body2" align="left" style={{ color: '#FFFFFF' }}>
          We’re excited to have you here. To get started, please enter your
          name, email address and phone number in the form on the right.
        </Typography>
      </Container>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={formikOnSubmit}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Box
                  style={{
                    padding: 30,
                    borderRadius: 25,
                    boxShadow: '0 0 10px #ccc',
                  }}
                >
                  <FormTextField
                    name="email"
                    icon="ic:round-email"
                    labelText="Email address"
                    placeholder="abc@example.com"
                    fullWidth
                    shrink
                    variant="standard"
                    error
                  />
                  <FormTextField
                    type="password"
                    name="password"
                    icon="mdi:account"
                    labelText="Password"
                    placeholder="********"
                    fullWidth
                    shrink
                    variant="standard"
                    error
                  />
                  <Button
                    color="primary"
                    disabled={!(props.isValid && props.dirty)}
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: !(props.isValid && props.dirty)
                        ? null
                        : '#DEAD00',
                      color: '#FFFFFF',
                      borderRadius: 50,
                      width: 180,
                      textTransform: 'initial',
                      fontWeight: 600,
                    }}
                  >
                    Continue
                  </Button>
                  <p>{msg}</p>
                  <ProgressLoader loading={loading} />
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </main>
    </div>
  );
}

Signinpage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Signinpage;
