import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
}));

export function FormRadio({ name, label, onChange, options, ...props }) {
  const classes = useStyles();
  const [val, setVal] = useState(props.value);
  return (
    <Field name={name} value={val}>
      {({ field, meta }) => {
        console.log(field);
        return (
          <>
            <FormControl
              component="fieldset"
              style={{ float: 'left', marginBottom: 20 }}
            >
              <FormLabel
                component="legend"
                style={{ textAlign: 'left', color: '#DEAD00' }}
              >
                {label}
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={val}
                onChange={(e) => {
                  onChange(e.target.value);
                  setVal(e.target.value);
                }}
                row
              >
                {options.map((op, i) => (
                  <FormControlLabel
                    key={i}
                    value={op.value}
                    control={<Radio />}
                    label={op.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      }}
    </Field>
  );
}
