import React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Box,
  Button,
} from '@material-ui/core';
import useStyles from './ActionList.styles';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { StringMasking } from 'src/utils/string';
import useButtonStyle from 'src/styles/button';

const ActionList = ({
  title,
  itemList,
  buttonLabel,
  onClick,
  onClickBackButton,
  setName: propsSetName,
  setIdentificationNum: propsSetIdentificationNum,
}) => {
  const buttonClasses = useButtonStyle();
  const classes = useStyles();

  const setName = (item) => {
    if (propsSetName) return propsSetName(item);
    return `${item.first_name} ${item.last_name}`;
  };

  const setIdentificationNum = (item) => {
    if (propsSetIdentificationNum)
      return StringMasking(propsSetIdentificationNum(item));
    return item?.identityNumber
      ? StringMasking(`${item?.identityNumber}`)
      : null;
  };

  const renderItemList = (itemList) => {
    return itemList.map((item, index) => (
      <ListItem
        key={`${item.name}${index}`}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          minHeight: '60px',
          marginBottom: '15px',
        }}
        button
        onClick={() => onClick(item.user_id)}
      >
        <ListItemIcon>
          <Avatar />
        </ListItemIcon>
        <ListItemText primary={setName(item)} />
        <ListItemText primary={setIdentificationNum(item)} />
        <ListItemSecondaryAction>
          <MzButton
            style={{
              width: 100,
            }}
            size={'small'}
            title={buttonLabel}
            onClick={() => onClick(item.user_id)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };

  return (
    <>
      <Typography variant="h5" color="secondary">
        {title}
      </Typography>
      <List
        style={{
          width: '100%',
        }}
      >
        {itemList.length > 0 && renderItemList(itemList)}
        {itemList.length === 0 && (
          <>
            <Typography variant="body2" className={classes.notFoundText}>
              0 _ 0
            </Typography>
            <Box pt={3} />
            <Typography variant="body2" className={classes.notFoundText}>
              No persons found.
            </Typography>

            <Box pt={3} />
            <Button
              startIcon={<ChevronLeftIcon />}
              className={buttonClasses.backButton}
              onClick={onClickBackButton}
            >
              Back
            </Button>
          </>
        )}
      </List>
    </>
  );
};

export default ActionList;
