import axios from './customAxios';

const countries = async () => {
  const { data } = await axios.get(
    `https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/country`,
  );

  // if (data && !data?.is_success) {
  //   console.log('i am here');
  //   throw Error(data?.message);
  // }

  // console.log(data);

  return data;
};

const commonSvc = { countries };

export default commonSvc;
