import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    border: '1px dashed black',
    borderRadius: 25,
    paddingTop: '20px',
    paddingBottom: '20px',
    cursor: 'pointer',
  },
}));
