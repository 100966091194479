import React from 'react';
import {
  ThemeProvider,
  createTheme,
  darken,
  lighten,
  useTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

const AppTheme = ({ children }) => {
  const baseTheme = useTheme();

  const selectedTheme = () => {
    const nextTheme = createTheme({
      overrides: {
        // MuiPaper: {
        //   root: {
        //     width: 'inherit',
        //   },
        // },
        // MuiFormControl: {
        //   root: {
        //     marginTop: 16,
        //     marginBottom: 16,
        //   },
        // },
      },
      typography: {
        fontFamily: 'Montserrat, Roboto',
      },
      palette: {
        secondary: {
          main: '#DEAD00',
        },
      },
    });
    return responsiveFontSizes(nextTheme);
  };

  return <ThemeProvider theme={selectedTheme()}>{children}</ThemeProvider>;
};

export default AppTheme;
