import { Box, Button, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import threeSteps from 'src/assets/requests/3steps.png';
import ActionList from 'src/components/ActionList';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
// import useStyles from './Requests.styles';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import { useProvideUser } from 'src/hooks/useUsers';
import useButtonStyle from 'src/styles/button';
import companySvc from 'src/services/company';

const DirectorList = () => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const { ListUserByCompanyRole, users } = useProvideUser();

  useEffect(() => {
    const company = companySvc.getCompany();
    ListUserByCompanyRole(company.companyId, 5);
  }, []);

  return (
    <>
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Personal Particulars',
            route: ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS,
          },
          {
            path: 'Change Director Particulars',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Change Director Particulars
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Change the particulars of an Active Director of our business
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                The information that can be changed includes:
              </Typography>
              <ul>
                <li>
                  <Typography align="justify" variant="body2">
                    Residential Address
                  </Typography>
                </li>
                <li>
                  <Typography align="justify" variant="body2">
                    Contact Information - Mobile Number & Email
                  </Typography>
                </li>
                <li>
                  <Typography align="justify" variant="body2">
                    Residency Status - ID Type, ID Number & Nationality
                  </Typography>
                </li>
                <li>
                  <Typography align="justify" variant="body2">
                    Name
                  </Typography>
                </li>
              </ul>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                The updated particulars for the Director
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                For change of address, a copy of proof of residency (In English)
                is required
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                For change of residency status, a copy of their NRIC/Passport is
                required
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                For change of name, a copy of deed poll is required
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={threeSteps} height={50} />
          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Basic Plan: SGD50
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Unlimited Plan: Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 5 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 3-5 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />
          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <ActionList
          title="Select Director to Change Particulars"
          itemList={users}
          buttonLabel="Edit"
          onClick={(directorId) => {
            history.push(
              ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(directorId),
            );
          }}
        />
      )}
      <Box py={5} />
    </>
  );
};

export default DirectorList;
