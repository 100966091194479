import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Paper,
  Grid,
  makeStyles,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import AlertBox from 'src/components/AlertBox';
import fourSteps from 'src/assets/requests/4steps.png';
import Stepper from 'src/components/Stepper';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { FormFileDropzone } from 'src/components/Form';
import DetailLabel from 'src/components/DetailLabel';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';

const tempSteps = [
  {
    id: 1,
    label: 'Share Capital',
  },
  {
    id: 2,
    label: 'Allotment',
  },
];

const currencyList = [
  { label: 'EUR' },
  { label: 'GBP' },
  { label: 'JPY' },
  { label: 'SGD' },
  { label: 'USD' },
];

const shareholderList = [
  { label: 'Gideon' },
  { label: 'Edison' },
  { label: 'Tan' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: 'black',
    display: 'flex',
    alignItems: 'left',
    backgroundColor: '#ECECEC',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const AllotmentShares = () => {
  const history = useHistory();
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const buttonClasses = useButtonStyle();
  const [step, setStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [countryCode, setCountryCode] = useState('SG');

  const step2validationSchema = Yup.object({
    currency: Yup.string().required('Currency is required'),
    ordinaryshares: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Ordinary Shares is required'),
    amount: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Amount is required'),
    capitalFiles: Yup.array().min(1),
  });

  const step3validationSchema = Yup.object({
    shareholder: Yup.string().required('Shareholder is required'),
    newshares: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('New Shares is required'),
    newcapital: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('New Capital is required'),
  });

  const step4validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 2,
      then: Yup.array().min(1),
    }),
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    setShowAlert(true);
  };

  const FormRow = ({ label, latest, balance }) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography variant="body1" align="left">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>{latest}</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>{balance}</Paper>
        </Grid>
      </>
    );
  };

  const stepOne = () => {
    return (
      <>
        <Typography variant="h6" color="secondary">
          Allotment of Shares
        </Typography>
        <Box p={1} />
        <Typography variant="h6" color="secondary">
          What can I do in this request?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Issue new Ordinary Shares fully payable in Cash for SGD, USD, GBP,
              EUR or JPY
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Allotment of new shares to existing or new <b>Individual</b>{' '}
              shareholder
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              For issue of other share type and currency, please submit under
              Other Request
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What information / document(s) do I need?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Number of Ordinary Share to issue & Paid-up Capital
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Copy of Bank Statement or Bank Receipt (jpg, png, pdf format)
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Shareholder particulars & copy of NRIC/ Passport (For New
              Shareholder)
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Allotment Details (Number of New Shares & Capital for each
              shareholder)
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          What are the steps?
        </Typography>
        <img src={fourSteps} height={50} />
        <Typography variant="h6" color="secondary">
          How much does it cost?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Basic Plan: SGD100
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Unlimited Plan: SGD100
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" color="secondary">
          How long does it take?
        </Typography>
        <ul>
          <li>
            <Typography align="justify" variant="body2">
              Time to fill form: {'<'} 5 min
            </Typography>
          </li>
          <li>
            <Typography align="justify" variant="body2">
              Average Processing Time: 10-15 days
            </Typography>
          </li>
        </ul>
        <Box p={1} />

        <MzButton
          title={'Lets get started'}
          onClick={() => {
            setStep(1);
          }}
        />
      </>
    );
  };

  const stepTwo = () => {
    return (
      <Formik
        initialValues={{
          currency: '',
          ordinaryshares: '',
          amount: '',
          capitalFiles: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={step2validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                <FormDropdown
                  autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
                  name="currency"
                  onChange={(event, newValue) => {
                    props.setFieldValue('currency', newValue?.label);
                  }}
                  optionList={currencyList}
                  labelText="Select Currency"
                  placeholder="Select Currency"
                  fullWidth
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                  defaultValue={currencyList[3]}
                />
                <FormTextField
                  name="ordinaryshares"
                  labelText="No. of Ordinary Shares *"
                  fullWidth={smBelow ? true : false}
                  variant="outlined"
                />
                <FormTextField
                  name="amount"
                  labelText="Amount of Issued/ Paid Up Share Capital *"
                  fullWidth={smBelow ? true : false}
                  shrink
                  variant="outlined"
                />
                <FormFileDropzone
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="capitalFiles"
                  labelText="Proof of Capital (Bank of Statement or Bank Receipt) *"
                />
              </Box>
            </Paper>
            <Box p={2} />
            <Box display="flex" alignItems="flex-start">
              <MzButton
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {
                  setStep(2);
                }}
                title={'Save & Proceed'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepThree = () => {
    return (
      <Formik
        initialValues={{
          shareholder: '',
          newshares: '',
          newcapital: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={step3validationSchema}
      >
        {(props) => (
          <Form style={{ width: '100%' }}>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                <DetailLabel label="Currency" value="Singapore Dollars" />
                <Box p={1} />
                <>
                  <Grid container spacing={1}>
                    <React.Fragment>
                      <Grid item xs={4} />
                      <Grid item xs={4}>
                        <Typography variant="body2">NEW</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">BALANCE</Typography>
                      </Grid>
                    </React.Fragment>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        label="No. of Shares"
                        latest="200"
                        balance="200"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        label="Issued/ Paid-up Capital"
                        latest="2000"
                        balance="2000"
                      />
                    </Grid>
                  </Grid>
                </>
              </Box>
            </Paper>
            <Box
              p={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h5" className={classes.fontWeight}>
                Allotment Details
              </Typography>
              <MzButton
                title={'ADD NEW SHAREHOLDER'}
                onClick={() => {
                  setStep(3);
                }}
              />
            </Box>
            <Paper
              style={{
                width: '100%',
                borderRadius: 10,
              }}
            >
              <Box p={3}>
                <FormDropdown
                  autoCompleteStyle={{ width: smBelow ? '100%' : '50%' }}
                  name="shareholder"
                  onChange={(event, newValue) => {
                    props.setFieldValue('shareholder', newValue?.label);
                  }}
                  optionList={shareholderList}
                  labelText="Select Shareholder"
                  placeholder="Select One"
                  fullWidth
                  containerWidth="50%"
                  shrink
                  variant="outlined"
                />
                <DetailLabel label="EXISTING NO. OF SHARES" value="1000" />
                <Box p={1} />
                <Box display="flex">
                  <FormTextField
                    name="newshares"
                    labelText="New Shares"
                    variant="outlined"
                  />
                  <Box p={1} />
                  <FormTextField
                    name="newcapital"
                    labelText="New Capital"
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Paper>
            <Box p={2} />
            <Box display="flex" alignItems="flex-start">
              <MzButton
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {
                  setShowAlert(true);
                }}
                title={'Submit'}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const stepFour = () => {
    return (
      <>
        <Typography variant="h6" align="left" className={classes.fontWeight}>
          Fill in information for New Shareholder
        </Typography>
        <Box p={1} />
        <Paper square>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Local" />
            <Divider orientation="vertical" flexItem />
            <Tab label="Foreigner" />
          </Tabs>
        </Paper>
        <Box p={1} />
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Box p={3}>
            <Box p={2} />
            <TabPanel value={tabValue} index={0}>
              {renderForm()}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {renderForm()}
            </TabPanel>
          </Box>
        </Paper>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          company_role_id: 5,
          name: '',
          nric_passport_no: '',
          email: '',
          mobile_number: '',
          identityFiles: [],
          residencyFiles: [],
          passportFiles: [],
        }}
        onSubmit={formikOnSubmit}
        validationSchema={step4validationSchema}
      >
        {(props) => (
          <Form>
            <FormTextField
              name="name"
              labelText="NAME (as in NRIC/FIN) *"
              placeholder="Name"
              shrink
              color="secondary"
              variant="outlined"
            />
            <FormTextField
              name="nric_passport_no"
              labelText="ID *"
              placeholder="ID"
              color="secondary"
              shrink
              variant="outlined"
            />
            <FormDropdownTextField
              name="mobile_number"
              labelText="Mobile Number *"
              placeholder="91234567"
              color="secondary"
              containerWidth="50%"
              fullWidth
              shrink
              variant="outlined"
              showFlag={false}
              onValueChange={(event, newValue) => {
                setCountryCode(newValue.code);
              }}
              countryCode={countryCode}
            />
            <FormTextField
              color="secondary"
              name="email"
              labelText="Email Address *"
              placeholder="Email Address"
              shrink
              variant="outlined"
            />
            {tabValue === 0 && (
              <FormFileDropzone
                containerWidth="50%"
                name="identityFiles"
                labelText="Proof of Identity *"
              />
            )}

            {tabValue === 2 && (
              <Box
                display="flex"
                flexDirection={smBelow ? 'column' : 'row'}
                style={{
                  gap: '20px',
                }}
              >
                <FormFileDropzone
                  containerWidth="50%"
                  name="passportFiles"
                  labelText="Copy of Passport *"
                />
                <FormFileDropzone
                  containerWidth="50%"
                  name="residencyFiles"
                  labelText="Proof of Residency (Must be in English) *"
                />
              </Box>
            )}

            <Box display="flex" pt={3} justifyContent="flex-start">
              <MzButton
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                title={'SAVE'}
                onClick={() => {
                  setStep(2);
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <AlertBox
        title="Request successfully added to cart!"
        isVisible={showAlert}
        topButtonLabel="Add More"
        topButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION);
        }}
        topButtonStyles={{
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid #000000',
        }}
        botButtonLabel="Go to cart"
        botButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonStyles={{
          color: '#FFFFFF',
          backgroundColor: '#FFB800',
        }}
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Share Information',
            route: ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION,
          },
          {
            path: 'Allotment of Shares',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />

      {(step === 1 || step === 2) && (
        <Stepper
          steps={tempSteps}
          providedStep={step - 1}
          setProvidedStep={(step) => {
            setStep(step + 1);
          }}
        />
      )}
      <Box
        flexDirection="column"
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ width: '100%' }}
      >
        {step === 0 && stepOne()}
        {step === 1 && stepTwo()}
        {step === 2 && stepThree()}
        {step === 3 && stepFour()}
      </Box>
      <Box p={4} />
    </>
  );
};

export default AllotmentShares;
