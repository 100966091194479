import React, { useState, useContext, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Paper,
  Divider,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
// import useStyles from './Requests.styles';
import SearchIcon from '@material-ui/icons/Search';
import MzButton from 'src/components/MzButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import { TAB_REQUESTS } from 'src/constants/corpsec';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import { ConfigContext } from 'src/contexts';
import fourSteps from 'src/assets/requests/4steps.png';
import { useProvideProduct } from 'src/hooks/useProduct';
import AlertBox from 'src/components/AlertBox';
import companySvc from 'src/services/company';

const ApptDirector = () => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();
  const company = companySvc.getCompany();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [countryCode, setCountryCode] = useState('SG');
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const { smBelow } = useBreakpoints();
  const [showAlert, setShowAlert] = useState(false);

  const {
    CreateOfficerApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  useEffect(() => {
    // get latest product
    ReadProduct(3);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    appointment_date: Yup.string().required('Appointment date is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
    identityFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 0,
      then: Yup.array().min(1),
    }),
    residencyFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
    passportFiles: Yup.array().when('tabValue', {
      is: () => tabValue === 1,
      then: Yup.array().min(1),
    }),
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: '100%' }}
        hidden={value !== index}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11; // in cart status
    values.user_id = user.user_id;
    try {
      setLoading(true);
      if (values.identityFiles.length > 0) {
        const identityFilesID = await S3UploadAndInsertDocuments(
          values.identityFiles[0],
          76,
          6,
          'officer_application_id',
        );
        values.nric_attachment_id = identityFilesID.document_id;
      }

      if (values.residencyFiles.length > 0) {
        const residencyFilesID = await S3UploadAndInsertDocuments(
          values.residencyFiles[0],
          112,
          6,
          'officer_application_id',
        );
        values.residency_attachment_id = residencyFilesID.document_id;
      }

      if (values.passportFiles.length > 0) {
        const passportFilesID = await S3UploadAndInsertDocuments(
          values.passportFiles[0],
          77,
          6,
          'officer_application_id',
        );
        values.passport_attachment_id = passportFilesID.document_id;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showErrorSnackbar('Error uploading documents');
      return;
    }

    let res = await CreateOfficerApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.officer_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const renderForm = () => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
      >
        <Formik
          initialValues={{
            company_role_id: 5,
            name: '',
            nric_passport_no: '',
            email: '',
            mobile_number: '',
            appointment_date: new Date(),
            identityFiles: [],
            residencyFiles: [],
            passportFiles: [],
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Box p={3}>
              <Form>
                <FormTextField
                  name="name"
                  labelText="NAME (as in NRIC/FIN) *"
                  placeholder="Name"
                  shrink
                  color="secondary"
                  variant="outlined"
                />
                <FormTextField
                  name="nric_passport_no"
                  labelText="ID*"
                  placeholder="ID"
                  color="secondary"
                  shrink
                  variant="outlined"
                />
                <FormDropdownTextField
                  name="mobile_number"
                  labelText="Mobile Number*"
                  placeholder="91234567"
                  color="secondary"
                  containerWidth="50%"
                  fullWidth
                  shrink
                  variant="outlined"
                  showFlag={false}
                  onValueChange={(event, newValue) => {
                    setCountryCode(newValue.code);
                  }}
                  countryCode={countryCode}
                />
                <FormTextField
                  color="secondary"
                  name="email"
                  labelText="Email Address*"
                  placeholder="Email Address"
                  shrink
                  variant="outlined"
                />
                <FormDatePicker
                  containerWidth="50%"
                  color="secondary"
                  name="appointment_date"
                  labelText="Date of Appointment*"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                />
                {tabValue === 0 && (
                  <FormFileDropzone
                    containerWidth="50%"
                    name="identityFiles"
                    labelText="Proof of Identity*"
                  />
                )}

                {tabValue === 2 && (
                  <Box
                    display="flex"
                    flexDirection={smBelow ? 'column' : 'row'}
                    style={{
                      gap: '20px',
                    }}
                  >
                    <FormFileDropzone
                      containerWidth="50%"
                      name="passportFiles"
                      labelText="Copy of Passport*"
                    />
                    <FormFileDropzone
                      containerWidth="50%"
                      name="residencyFiles"
                      labelText="Proof of Residency (Must be in English)*"
                    />
                  </Box>
                )}

                <Box display="flex" pt={3} justifyContent="flex-start">
                  <MzButton
                    loading={requestSubmitting || loading}
                    disabled={!(props.isValid && props.dirty)}
                    type="submit"
                    title={'SUBMIT'}
                    onClick={() => {
                      setStep(2);
                    }}
                  />
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      </Paper>
    );
  };

  return (
    <>
      <AlertBox
        title="Item added to cart"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
        topButtonLabel="View your cart"
        botButtonLabel="Back to requests"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change of Officers',
            route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
          },
          {
            path: 'Appointment of Director',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Appointment of Director
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Appoint New Local Director or New Foreign Director for our
                business
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Personal particulars of the New Director
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                For local director, a copy of their NRIC / FIN (jpg,png, pdf
                format)
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                For foreign director, a copy of their Passport and Proof of
                Residency
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={fourSteps} height={50} />
          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Basic Plan: SGD50
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Unlimited Plan: Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 5 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 3-5 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />

          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <>
          <Typography variant="h6" color="secondary">
            Fill in information for New Director
          </Typography>
          <Paper square>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Local" />
              <Divider orientation="vertical" flexItem />
              <Tab label="Foreigner" />
            </Tabs>
          </Paper>
          <Box p={1} />
          <TabPanel value={tabValue} index={0}>
            {renderForm()}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {renderForm()}
          </TabPanel>
        </>
      )}
      <Box py={5} />
    </>
  );
};

export default ApptDirector;
