import React, { useEffect, forwardRef } from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from './CapTable.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import { useProvideCapTable } from 'src/hooks/useCapTable';

const CapTable = () => {
  const classes = useStyles();
  const { xsBelow, mdBelow } = useBreakpoints();
  const { ListCapTables, capTables } = useProvideCapTable();
  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  };

  useEffect(() => {
    ListCapTables();
  }, []);

  const mapCapTable = () => {
    return capTables.map((cap, index) => ({
      name: cap.Name,
      type: cap.shareholder_type_name,
      security: cap.share_type_name,
      count: cap.user_allotment,
      percentage: cap.user_allotment + '%',
    }));
  };

  const mapAllocationTable = () => {
    return capTables.map((cap, index) => ({
      name: cap.Name,
      type: cap.shareholder_type_name,
      security: cap.share_type_name,
      count: cap.user_allotment,
      capital: cap.user_allotment,
    }));
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={xsBelow ? 'column' : 'row'}
        justifyContent="space-around"
        width="100%"
      >
        <Box
          width={mdBelow ? (xsBelow ? '100%' : '33%') : '25%'}
          style={{
            padding: 30,
            borderRadius: 5,
            boxShadow: '0 0 10px #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" className={classes.fontWeight}>
            {capTables.length}
          </Typography>
          <Box p={1} />
          <Typography variant="body1">Current Shareholders</Typography>
        </Box>
        <Box p={1} />
        <Box
          width={mdBelow ? (xsBelow ? '100%' : '33%') : '25%'}
          style={{
            padding: 30,
            borderRadius: 5,
            boxShadow: '0 0 10px #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" className={classes.fontWeight}>
            0
          </Typography>
          <Box p={1} />
          <Typography variant="body1">Pending Shareholders</Typography>
        </Box>
        <Box p={1} />
        <Box
          width={mdBelow ? (xsBelow ? '100%' : '33%') : '25%'}
          style={{
            padding: 30,
            borderRadius: 5,
            boxShadow: '0 0 10px #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" className={classes.fontWeight}>
            0
          </Typography>
          <Box p={1} />
          <Typography variant="body1">Previous Shareholders</Typography>
        </Box>
      </Box>
      <Box p={3} />
      <Typography variant="h5" className={classes.fontWeight}>
        Current Cap Table
      </Typography>
      <Box p={1} />
      <MaterialTable
        options={{
          filtering: true,
          paging: false,
          toolbar: false,
          draggable: false,
        }}
        style={{ width: '100%' }}
        icons={tableIcons}
        columns={[
          { title: 'Shareholder Name', field: 'name' },
          { title: 'Shareholder Type', field: 'type' },
          { title: 'Security Type', field: 'security' },
          { title: 'Share Count', field: 'count' },
          { title: 'Percentage', field: 'percentage' },
        ]}
        data={mapCapTable()}
      />
      <Box p={1} />
      {/* <Typography variant="h6" className={classes.fontWeight}>
        Total Paid Up Capital: 0.00 SGD
      </Typography>
      <Box p={5} />
      <Typography variant="h5" className={classes.fontWeight}>
        Pending Allocation Table
      </Typography>
      <Box p={1} />
      <MaterialTable
        options={{
          filtering: true,
          paging: false,
          toolbar: false,
          draggable: false,
        }}
        style={{ width: '100%' }}
        icons={tableIcons}
        columns={[
          { title: 'Shareholder Name', field: 'name' },
          { title: 'Shareholder Type', field: 'type' },
          { title: 'Security Type', field: 'security' },
          { title: 'Share Count', field: 'count' },
          { title: 'Paid Up Capital', field: 'capital' },
        ]}
        data={mapAllocationTable()}
      /> */}
      <Box p={5} />
    </>
  );
};

export default CapTable;
