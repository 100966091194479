import React, { useEffect, useState } from 'react';
import { useProvideCart } from 'src/hooks/useCart';
import useSnackbar from 'src/hooks/useSnackbar';
import authSvc from 'src/services/authentication';
import { useLocation } from 'react-router-dom';

export const ConfigContext = React.createContext({});

export const ConfigContextProvider = ({ children }) => {
  const auth = authSvc.getAuthData();
  const location = useLocation();

  const clearCart = () => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        cart: {},
        cartList: [],
      };
    });
  };

  const fetchCart = (user) => {
    ListCart(user.user_id)
      .then((res) => {
        const initState = {};
        if (res.status === 'success') {
          initState.cartList = res.body.cart?.items;
          initState.cart = res.body.cart;
          setConfigState((prevState) => {
            return {
              ...prevState,
              cart: res.body.cart || {},
              cartList: res.body.cart?.items || [],
            };
          });
        }
      })
      .catch((e) => {
        setConfigState((prevState) => {
          return {
            ...prevState,
          };
        });
      });
  };

  const setUser = (user) => {
    if (user.user_id) {
      fetchCart(user);
    }
    setConfigState((prevState) => {
      return {
        ...prevState,
        user: user,
      };
    });
  };

  const {
    AddCart,
    DeleteCartItem,
    ListCart,
    cartError,
    cartSubmitting,
  } = useProvideCart();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  useEffect(() => {
    if (cartError) {
      showErrorSnackbar(cartError);
    }
  }, [cartError]);

  useEffect(() => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        cartSubmitting,
      };
    });
  }, [cartSubmitting]);

  useEffect(() => {
    if (auth != undefined && auth.is_success === true) {
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (!params.redirect || params.redirect !== 'checkout') {
        // Do not fetch cart if redirected back from payment gateway, due to delay in callback, cart is still not cleared
        fetchCart(auth.user);
      }
    }
  }, []);

  const addWebInfo = (webInfo) => {
    setConfigState((prevState) => {
      delete prevState.webInfo.webdev_category_name;
      return {
        ...prevState,
        webInfo: { ...prevState.webInfo, ...webInfo },
      };
    });
  };

  const addCart = async (cart, successMsg) => {
    const res = await AddCart(cart);
    if (res.status === 'success') {
      setConfigState((prevState) => ({
        ...prevState,
        cartList: [...prevState.cartList, res.body.cart],
        cart: res.body,
      }));
      showSuccessSnackbar('Item added to cart.');
    }
    return res;
  };

  const removeCart = async (cartList, index) => {
    const itemToRemove = cartList[index];
    const res = await DeleteCartItem(itemToRemove.id);
    if (res.status === 'success') {
      cartList.splice(index, 1);
      setConfigState((prevState) => ({
        ...prevState,
        cartList: cartList,
      }));
      showSuccessSnackbar('Item removed from cart.');
    }
    return res;
  };

  const [configState, setConfigState] = useState({
    cartList: [],
    cart: {},
    cartSubmitting,
    webInfo: [],
    user: auth?.user || {},
    addCart,
    clearCart,
    removeCart,
    addWebInfo,
    setUser,
    fetchCart,
  });

  return (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  );
};
