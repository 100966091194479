import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ROUTES from '../constants/routes';
import Dashboard from '../pages/dashboard';
import Messagepage from '../pages/messagepage';
import Otppage from '../pages/otppage';
import Signuppage from '../pages/signuppage';
import Signinpage from '../pages/signinpage';
import Homepage from '../pages/homepage';
import Corpsec from '../pages/legal/corpsec';
import PaymentCart from '../pages/paymentcart';
import OrderHistory from '../pages/orderhistory';
import OrderHistoryDetails from '../pages/orderhistorydetails';
import WebService from '../pages/webservice';
import WebServiceCategory from '../pages/webservice/category';
import WebServiceTheme from '../pages/webservice/theme';
import WebServiceThemeInfo from '../pages/webservice/themeinfo';
import WebServicePlan from '../pages/webservice/plan';
import ProgressLoader from '../components/Progress';
import authSvc from '../services/authentication';
import { useSelector } from 'src/Provider';
import WebDev from '../pages/webdev';
import CreateWebsite from '../pages/createwebsite';
import CreateWebsiteTitle from '../pages/createwebsite/content';
import CreateWebsiteCorpidentity from '../pages/createwebsite/corpidentity';
import CreateWebsiteBackground from '../pages/createwebsite/background';
import ResetPasswordPage from 'src/pages/reset-password';

const GuardedRoute = ({ component: Component, auth, ...rest }) => {
  console.log(auth, '....auth...');
  return (
    <Route
      {...rest}
      render={
        (props) =>
          auth === true ? <Component {...props} /> : <Redirect to="/" />
        // auth === true ? <Redirect to={ROUTES.DASHBOARD} /> : <Redirect to="/" />
      }
    />
  );
};

const Routes = () => {
  const globalState = useSelector((s) => s.global);
  const [auth, setAuth] = useState({ isAuth: false, loading: true });
  useEffect(() => {
    setAuth({ isAuth: authSvc.isAuthenticated(), loading: false });
  }, []);

  useEffect(() => {
    console.log(globalState?.auth);
    if (globalState?.auth?.timespan !== 0)
      setAuth({ isAuth: globalState.auth.isAuthenticated, loading: false });
  }, [globalState]);

  if (auth.loading) return <ProgressLoader loading />;

  return (
    <Suspense fallback={<h1>loading</h1>}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return auth.isAuth ? (
              <Redirect to={ROUTES.DASHBOARD} />
            ) : (
              <Homepage />
            );
          }}
        />
        <Route exact path={ROUTES.HOMEPAGE}>
          <Homepage />
        </Route>
        <Route exact path={ROUTES.OTPPAGE}>
          <Otppage />
        </Route>
        <Route exact path={ROUTES.MESSAGEPAGE}>
          <Messagepage />
        </Route>
        <Route exact path={ROUTES.SIGNUPPAGE}>
          <Signuppage />
        </Route>
        <Route exact path={ROUTES.SIGNINPAGE}>
          <Signinpage />
        </Route>

        <GuardedRoute
          exact
          path={ROUTES.CHANGE_PASSWORD}
          auth={auth.isAuth}
          component={ResetPasswordPage}
        />
        {/* <Route exact path={ROUTES.PAYMENTCART}>
          <PaymentCart />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.PAYMENTCART}
          auth={auth.isAuth}
          component={PaymentCart}
        />
        {/* <Route exact path={ROUTES.ORDERHISTORY}>
          <OrderHistory />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.ORDERHISTORY}
          auth={auth.isAuth}
          component={OrderHistory}
        />
        <GuardedRoute
          exact
          path={ROUTES.ORDERHISTORYDETAILS(':id')}
          auth={auth.isAuth}
          component={OrderHistoryDetails}
        />
        {/* <Route exact path={ROUTES.CORPSEC_DOC}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_DOC}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_PRO}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_PRO}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_TASK}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_BOARD}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_CAP}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_APPT_ADMIN}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_APPT_ADMIN}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_APPT_DIRECTOR}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_APPT_DIRECTOR}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_RESGN_ADMIN}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_RESGN_ADMIN}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route path={ROUTES.CORPSEC_REQ_RESGN_ADMIN_INFO(':id')}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_RESGN_ADMIN_INFO(':id')}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_RESGN_DIRECTOR}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_RESGN_DIRECTOR}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route path={ROUTES.CORPSEC_REQ_RESGN_DIRECTOR_INFO(':id')}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          path={ROUTES.CORPSEC_REQ_RESGN_DIRECTOR_INFO(':id')}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_CHANGE_OFFICER}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_OFFICER}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_COMPANY_NAME}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_COMPANY_FINANCIAL_YEAR_END}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_BUSINESS_ACTIVITIES}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_COMPANY_ADDRESS}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_SHARE_INFORMATION}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_ALLOTMENT_SHARES}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_TRANSFER_SHARES}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_OTHER_RESOLUTIONS}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQUESTS_DECLARE_INTERIM_DIVIDEND}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQUESTS_OPEN_BANK_ACCOUNT}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQUESTS_CLOSE_BANK_ACCOUNT}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQUESTS_OTHER_REQUEST}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_CHANGE_PERSONAL_PARTICULARS}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_DIRECTOR_LIST}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_DIRECTOR_LIST}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route path={ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(':id')}>
          <Corpsec />
        </Route> */}
        <GuardedRoute
          path={ROUTES.CORPSEC_REQ_CHANGE_DIRECTOR_PARTICULARS(':id')}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute
          path={ROUTES.CORPSEC_REQ_CHANGE_SHAREHOLDER_PARTICULARS(':id')}
          auth={auth.isAuth}
          component={Corpsec}
        />
        {/* <Route exact path={ROUTES.WEBSERVICE}>
          <WebService />
        </Route> */}
        <GuardedRoute
          exact
          path={ROUTES.WEBSERVICE}
          auth={auth.isAuth}
          component={WebService}
        />
        {/* <Route
          path={ROUTES.WEBSERVICE_PLAN(':packageid', ':categoryid', ':themeid')}
        >
          <WebServicePlan />
        </Route> */}
        <GuardedRoute
          path={ROUTES.WEBSERVICE_PLAN(':packageid', ':categoryid', ':themeid')}
          auth={auth.isAuth}
          component={WebServicePlan}
        />
        {/* <Route
          path={ROUTES.WEBSERVICE_THEME_INFO(
            ':packageid',
            ':categoryid',
            ':themeid',
          )}
        >
          <WebServiceThemeInfo />
        </Route> */}
        <GuardedRoute
          path={ROUTES.WEBSERVICE_THEME_INFO(
            ':packageid',
            ':categoryid',
            ':themeid',
          )}
          auth={auth.isAuth}
          component={WebServiceThemeInfo}
        />
        {/* <Route path={ROUTES.WEBSERVICE_THEME(':packageid', ':categoryid')}>
          <WebServiceTheme />
        </Route> */}
        <GuardedRoute
          path={ROUTES.WEBSERVICE_THEME(':packageid', ':categoryid')}
          auth={auth.isAuth}
          component={WebServiceTheme}
        />
        {/* <Route path={ROUTES.WEBSERVICE_CATEGORY(':packageid')}>
          <WebServiceCategory />
        </Route> */}

        <GuardedRoute
          path={ROUTES.WEBSERVICE_CATEGORY(':packageid')}
          auth={auth.isAuth}
          component={WebServiceCategory}
        />
        <GuardedRoute
          exact
          auth={auth.isAuth}
          path={ROUTES.CORPSEC}
          component={Corpsec}
        />
        <GuardedRoute
          exact
          path={ROUTES.DASHBOARD}
          auth={auth.isAuth}
          component={Dashboard}
        />
        <GuardedRoute
          exact
          path={ROUTES.DASHBOARD}
          auth={auth.isAuth}
          component={Corpsec}
        />
        <GuardedRoute exact path={ROUTES.WEBDEV}>
          <WebDev />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE_IMAGES(':webdevid')}>
          <CreateWebsiteBackground />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE_CORPIDENTITY(':webdevid')}>
          <CreateWebsiteCorpidentity />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE_CONTENT(':webdevid')}>
          <CreateWebsiteTitle />
        </GuardedRoute>
        <GuardedRoute path={ROUTES.CREATEWEBSITE(':webdevid')}>
          <CreateWebsite />
        </GuardedRoute>
      </Switch>
    </Suspense>
  );
};

export default Routes;
