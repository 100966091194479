import React from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';

const EnhancedTextField = ({
  children,
  disabled,
  required,
  variant = 'standard',
  shrink = false,
  ...props
}) => (
  <>
    <Box style={{ display: 'flex' }}>
      <Icon icon={props.icon} width="20" height="20" />
      <Typography variant="body2" align="left" style={{ paddingLeft: 5 }}>
        {props.labelText}
      </Typography>
      <Typography
        variant="body2"
        align="left"
        style={{ paddingLeft: 5, fontStyle: 'italic', color: '#808080' }}
      >
        {props.optional && '(optional)'}
      </Typography>
    </Box>
    <TextField
      disabled={disabled}
      InputLabelProps={shrink ? { shrink } : {}}
      variant={variant}
      style={{ paddingBottom: 30 }}
      {...props}
    />
  </>
);

export default EnhancedTextField;
