import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MzButton from 'src/components/MzButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import threeSteps from 'src/assets/requests/3steps.png';
import { useProvideProduct } from 'src/hooks/useProduct';
import AlertBox from 'src/components/AlertBox';
import companySvc from 'src/services/company';

const ApptAdmin = () => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();
  const company = companySvc.getCompany();

  const history = useHistory();
  const [step, setStep] = useState(1);
  const [countryCode, setCountryCode] = useState('SG');
  const [tabValue, setTabValue] = useState(0);
  const { smBelow } = useBreakpoints();
  const [showAlert, setShowAlert] = useState(false);

  const {
    CreateOfficerApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { addCart, user } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    nric_passport_no: Yup.string().required('ID is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Mobile Number is required'),
  });

  useEffect(() => {
    // get latest product
    ReadProduct(4);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.companyId;
    values.company_application_status_id = 11; // in cart status
    values.user_id = user.user_id;
    let res = await CreateOfficerApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        type: 'Appointment of Administrator',
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.officer_application_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setShowAlert(true);
      }
    }
  };

  const renderForm = () => {
    return (
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
        }}
        elevation={4}
      >
        <Formik
          initialValues={{
            company_role_id: 15,
            name: '',
            nric_passport_no: '',
            email: '',
            mobile_number: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Box p={3}>
              <Form>
                <FormTextField
                  name="name"
                  labelText="NAME (as in NRIC/FIN) *"
                  placeholder="Name"
                  shrink
                  variant="outlined"
                  color="secondary"
                />
                <FormTextField
                  name="nric_passport_no"
                  labelText="ID*"
                  placeholder="ID"
                  shrink
                  variant="outlined"
                  color="secondary"
                />
                <FormDropdownTextField
                  name="mobile_number"
                  labelText="Mobile Number*"
                  placeholder="91234567"
                  shrink
                  variant="outlined"
                  showFlag={false}
                  color="secondary"
                  containerWidth="50%"
                  fullWidth
                  onValueChange={(event, newValue) => {
                    setCountryCode(newValue.code);
                  }}
                  countryCode={countryCode}
                />
                <FormTextField
                  name="email"
                  labelText="Email Address*"
                  placeholder="Email Address"
                  shrink
                  variant="outlined"
                  color="secondary"
                />

                <Box display="flex" pt={3} justifyContent="flex-start">
                  <MzButton
                    disabled={!(props.isValid && props.dirty)}
                    type="submit"
                    title={'SUBMIT'}
                    onClick={() => {
                      setStep(2);
                    }}
                    loading={requestSubmitting}
                  />
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      </Paper>
    );
  };

  return (
    <>
      <AlertBox
        title="Item added to cart"
        isVisible={showAlert}
        topButtonAction={() => {
          history.push(ROUTES.PAYMENTCART);
        }}
        botButtonAction={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
        topButtonLabel="View your cart"
        botButtonLabel="Back to requests"
        iconImg="teenyicons:tick-circle-outline"
        iconColor="green"
      />
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ_CHANGE_OFFICER);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change of Officers',
            route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
          },
          {
            path: 'Appointment of Administrator',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      {step === 1 && (
        <>
          <Typography variant="h6" color="secondary">
            Appointment of Administrator
          </Typography>
          <Box p={1} />
          <Typography variant="h6" color="secondary">
            What can I do in this request?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Appoint an Administrator or Authorized Agent for our business
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Administrator or Authorized Agent is a designated person to act
                on behalf of the Directors. Signing resolution will still be the
                Director’s role and responsibility.
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What information / document(s) do I need?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Personal particulars of the person to be appointed as
                Administrator
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Local mobile number and email of the Administrator
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            What are the steps?
          </Typography>
          <img src={threeSteps} height={50} />
          <Typography variant="h6" color="secondary">
            How much does it cost?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Free
              </Typography>
            </li>
          </ul>
          <Typography variant="h6" color="secondary">
            How long does it take?
          </Typography>
          <ul>
            <li>
              <Typography align="justify" variant="body2">
                Time to fill form: {'<'} 2 min.
              </Typography>
            </li>
            <li>
              <Typography align="justify" variant="body2">
                Average Processing Time: 1-2 days
              </Typography>
            </li>
          </ul>
          <Box p={1} />
          <MzButton
            title={'Lets get started'}
            onClick={() => {
              setStep(2);
            }}
          />
        </>
      )}
      {step === 2 && (
        <>
          <Typography variant="h6" color="secondary">
            Fill in information for New Administrator
          </Typography>
          <Box py={2} />
          {renderForm()}
        </>
      )}
      <Box py={5} />
    </>
  );
};

export default ApptAdmin;
