import React, { useState, useEffect, useContext } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  ButtonBase,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { CorpsecNameBox } from 'src/components/Corpsec';
import AuthLayout from 'src/components/AuthLayout';
import { useProvideOrder } from 'src/hooks/useOrder';
import moment from 'moment';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const OrderHistory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { xsBelow } = useBreakpoints();
  const { showSuccessSnackbar } = useSnackbar();
  const { clearCart, user } = useContext(ConfigContext);

  const { ListOrders, orders, orderLoading } = useProvideOrder();

  useEffect(() => {
    ListOrders(user.user_id);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.redirect && params.redirect === 'checkout') {
      // refetch cart
      clearCart();
      showSuccessSnackbar('Order submitted');
    }
  }, []);

  const TypographyStatus = ({ status }) => {
    let statusColor, textLabel;
    switch (status) {
      case 'completed':
        statusColor = '#24FF00';
        textLabel = 'Completed';
        break;
      case 'PENDING':
        statusColor = '#FF7F09';
        textLabel = 'Pending';
        break;
      case 'paid':
        statusColor = '#041DFF';
        textLabel = 'Paid';
        break;
      case 'processing':
        statusColor = '#FF7F09';
        textLabel = 'Processing';
        break;
      case 'failed':
        statusColor = '#FF4D00';
        textLabel = 'Failed';
        break;
    }
    return (
      <Typography
        variant="body1"
        align="right"
        style={{ fontWeight: 600, color: statusColor }}
      >
        {textLabel}
      </Typography>
    );
  };

  const renderStep1 = () => {
    return (
      <Box
        style={{
          width: '100%',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h6"
            align="left"
            color="secondary"
            style={{
              fontWeight: 600,
            }}
          >
            Orders
          </Typography>
          {!orderLoading && orders.length === 0 && (
            <>
              <Box py={5} />
              <Typography variant="h6">No order at the moment</Typography>
            </>
          )}

          {orders.length !== 0 &&
            orders.map((item, index) => (
              <>
                <Box pt={3} />
                <ButtonBase key={`${item.id}${index}`}>
                  <Box
                    style={{
                      padding: 30,
                      borderRadius: 25,
                      boxShadow: '0 0 10px #ccc',
                      width: '100%',
                    }}
                    onClick={() => {
                      history.push(ROUTES.ORDERHISTORYDETAILS(item.id));
                    }}
                  >
                    <Box
                      display={xsBelow ? 'initial' : 'flex'}
                      justifyContent="space-between"
                    >
                      <Box display={xsBelow ? 'initial' : 'flex'}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          # {item.id}
                        </Typography>
                        <Box pr={4} />
                        <Typography variant="body1" align="left">
                          Date:{' '}
                          {moment(item.created_at).format(`DD/MM/yyyy HH:mm`)}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        align={'right'}
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        S$ {item.totalPrice}
                      </Typography>
                    </Box>

                    <Box
                      display={xsBelow ? 'initial' : 'flex'}
                      justifyContent="space-between"
                    >
                      <TypographyStatus status={item.status} />
                    </Box>
                  </Box>
                </ButtonBase>
              </>
            ))}
        </Box>
        <Box pb={5} />
      </Box>
    );
  };

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        {/* <AppBar
          position="static"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h5" noWrap>
              CORPORATE SECRETARY
            </Typography>
          </Toolbar>
        </AppBar> */}

        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          className={classes.content}
        >
          <Container>
            {/* <CorpsecNameBox /> */}
            <Box pt={3} display="flex" flexDirection="column">
              {orderLoading ? (
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <EnhancedSkeleton
                    layout={[
                      {
                        loaders: [
                          { variant: 'rect', height: 100, width: '100%' },
                        ],
                        repeat: 5,
                        mt: 4.25,
                      },
                    ]}
                  />
                </div>
              ) : (
                renderStep1()
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default OrderHistory;
