import React from 'react';
import {
  Container,
  Typography,
  Hidden,
  Drawer,
  AppBar,
  Toolbar,
  Slide,
  useScrollTrigger,
  Box,
  makeStyles,
} from '@material-ui/core';
import mlogo from '../../assets/m360.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#161616',
  },
  appBar: {
    backgroundColor: '#161616',
  },
}));

const sideDrawer = (title, subTitle) => {
  return (
    <div>
      <Container style={{ paddingTop: 30 }}>
        <img src={mlogo} height={30} style={{ display: 'flex' }} />
        <Typography
          variant="h6"
          align="left"
          style={{
            color: '#FFFFFF',
            fontWeight: 600,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {title}
        </Typography>
        <Typography variant="body2" align="left" style={{ color: '#FFFFFF' }}>
          {subTitle}
        </Typography>
      </Container>
    </div>
  );
};

const topAppbar = (title, subTitle) => {
  return (
    <div>
      <Container
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img src={mlogo} height={70} />
        <Box>
          <Typography
            variant="h6"
            align="left"
            style={{
              color: '#FFFFFF',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" align="left" style={{ color: '#FFFFFF' }}>
            {subTitle}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const IntroBar = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Hidden mdUp implementation="css">
        <HideOnScroll container>
          <AppBar>
            <Toolbar
              classes={{
                root: classes.appBar,
              }}
            >
              {topAppbar(title, subTitle)}
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {sideDrawer(title, subTitle)}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default IntroBar;
