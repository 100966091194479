import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Box,
  Stepper as MuiStepper,
  Step,
  StepButton,
} from '@material-ui/core';
import useStyles from './Stepper.styles';

const Stepper = ({
  steps = [],
  providedStep,
  setProvidedStep,
  disabled = false,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(providedStep);
  }, [providedStep]);

  const handleStep = (step) => () => {
    setActiveStep(step);
    if (setProvidedStep) setProvidedStep(step);
  };

  return (
    <Box width="100%">
      <MuiStepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        {steps.map((step, index) => {
          const { label } = step;

          return (
            <Step
              key={label}
              classes={{
                alternativeLabel: classes.alternativeLabel,
              }}
            >
              <StepButton
                classes={{
                  root: classes.color,
                }}
                onClick={disabled ? null : handleStep(index)}
                disableRipple={disabled ? true : false}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </MuiStepper>
    </Box>
  );
};

export default Stepper;
