require('dotenv').config();

export const Config = {
  checkoutAuthToken: process.env.REACT_APP_CHECKOUT_AUTH_TOKEN,
  checkoutPaymentAuthToken: process.env.REACT_APP_CHECKOUT_PAYMENT_AUTH_TOKEN,
  addressURL: process.env.REACT_APP_ADDRESS_URL,
  apiURL: process.env.REACT_APP_API_URL,
  apiUrl: process.env.REACT_APP_BASE_URL,
  cartURL: process.env.REACT_APP_CART_URL,
  checkoutURL: process.env.REACT_APP_CHECKOUT_URL,
  dataURL: process.env.REACT_APP_DATA_URL,
  requestURL: process.env.REACT_APP_REQUEST_URL,
  shareholderURL: process.env.REACT_APP_SHAREHOLDER_URL,
  userURL: process.env.REACT_APP_USER_URL,
};
