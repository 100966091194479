import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RepeatIcon from '@material-ui/icons/Repeat';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { EnhancedSkeleton } from 'src/components/Enhanced';
import timelineSvc from 'src/services/timeline';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();
  const [timeline, setTimeline] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        const data = await timelineSvc.timeline();
        if (data) setTimeline(data.reverse());
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Timeline align="alternate">
      {!loading && timeline.length == 0 ? <h1>No Activities</h1> : null}
      {loading ? (
        <div
          style={{
            width: '100%',
          }}
        >
          <EnhancedSkeleton
            layout={[
              {
                loaders: [{ variant: 'rect', height: 50, width: '100%' }],
                repeat: 5,
                mt: 4.25,
              },
            ]}
          />
        </div>
      ) : null}
      {timeline?.map((event, i) => (
        <TimelineItem key={i}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {moment(event.event_datetime).format(`DD/MM/yyyy`)}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>
              <PersonAddIcon />
            </TimelineDot>
            {timeline.length === i + 1 ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper
              elevation={3}
              className={classes.paper}
              style={{ textAlign: i % 2 ? 'end' : 'start' }}
            >
              <Typography variant="h6" component="h1">
                {event.event_description}
              </Typography>
              <Typography>{event.event_name}</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
